import { Refresh } from "@mui/icons-material"
import { Box, Button, Container, Typography } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import { PackError } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { packErrorResolve, packErrorsGet } from "queries/fetchPackError"
import { useCallback, useEffect, useState } from "react"
import { germanDateFormatter } from "utils/datefmt"

const PackErrorPage = (): JSX.Element => {
  const [loading, setloading] = useState<boolean>(false)
  const [packErrorsData, setPackErrorsData] = useState<PackError[]>([])
  const dispatch = useAppDispatch()

  const fetchPackErrorsData = useCallback(async () => {
    setloading(true)
    const res = await packErrorsGet()
    if (res?.packErrors) {
      enqueueSnackbar("Fehler geladen!", { variant: "success" })
      setPackErrorsData(res.packErrors)
    }
    setloading(false)
  }, [setPackErrorsData])

  useEffect(() => {
    fetchPackErrorsData()
  }, [fetchPackErrorsData])

  const setSolved = async (id: number) => {
    const res = await packErrorResolve(id)
    if (res?.packErrorResolve) {
      dispatch(qSnack({ msg: "Fehler gelöst", severity: "success" }))
      fetchPackErrorsData()
    } else {
      dispatch(qSnack({ msg: "Fehler beim Löschen", severity: "error" }))
    }
  }

  const colsPackageError: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "detail",
      headerName: "Detail",
      width: 600,
      renderCell: (params) => (
        <Typography
          variant="body2"
          component="div"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "created",
      headerName: "Datum",
      width: 150,
      valueFormatter: (params) =>
        germanDateFormatter.format(new Date(params.value as string)),
    },
    { field: "orderID", headerName: "Bestell ID" },
    { field: "customerID", headerName: "Kunde ID" },
    { field: "addressID", headerName: "Adresse ID" },
    { field: "packer", headerName: "Packer", width: 150 },
    {
      field: "action",
      headerName: "Aktion",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => {
            setSolved(params.row.id as number)
          }}
        >
          Problem gelöst
        </Button>
      ),
    },
  ]

  return (
    <Container maxWidth="xl">
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flex: 1,
            marginBottom: 10,
          }}
        >
          <Button
            variant="contained"
            startIcon={<Refresh />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              fetchPackErrorsData()
            }}
          >
            Aktualisieren
          </Button>
        </Box>
        {!loading && packErrorsData.length === 0 && (
          <Typography
            variant="h6"
            component="h1"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            Keine Fehler aufgetreten
          </Typography>
        )}

        <Box sx={{ height: "80vh", width: "100%" }}>
          <DataGridPro
            rows={packErrorsData}
            getRowHeight={() => "auto"}
            loading={loading}
            columns={colsPackageError}
            disableRowSelectionOnClick
            localeText={dataGridDEde}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default PackErrorPage
