import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { ArticleItem } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { articleSearch } from "queries/articleSearch"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

const columns: GridColDef<ArticleItem>[] = [
  {
    field: "bdb",
    headerName: "BDB",
    width: 120,
  },
  {
    field: "pflNR",
    headerName: "PflNR",
    width: 120,
  },
  {
    field: "botname",
    headerName: "Botanischer Name",
    renderCell: (params) => {
      return (
        <Box>
          <Typography variant="body1" component="div">
            {params.row.botname}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {params.row.sorte}
          </Typography>
        </Box>
      )
    },
    width: 350,
  },
  {
    field: "quali",
    headerName: "Qualität",
    width: 200,
  },
  {
    field: "lform",
    headerName: "Lieferform",
    width: 200,
  },
  {
    field: "size",
    headerName: "Größe",
    width: 120,
    valueGetter: (params) => {
      if (params.row.sizeFrom && params.row.sizeTo) {
        return params.row.sizeFrom + "-" + params.row.sizeTo
      }
    },
  },
  {
    field: "price",
    headerName: "Preis",
    type: "number",
    width: 120,
  },
  {
    field: "reducedPrice",
    headerName: "Aktions Preis",
    type: "number",
    width: 120,
  },
]

const ArticleSearchPage = (): JSX.Element => {
  const searchRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [displayInactiveVariants, setDisplayInactiveVariants] =
    useState<boolean>(false)
  const [rows, setRows] = useState<ArticleItem[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    if (searchRef.current !== null) {
      searchRef.current.focus()
    }
  }, [])

  const handleSearchCommit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchValue = searchRef.current?.value
      if (searchValue !== undefined && searchValue !== null) {
        if (searchValue === "") {
          enqueueSnackbar("Bitte geben Sie einen Suchbegriff ein", {
            variant: "info",
          })
        } else {
          setIsLoading(true)
          articleSearch(searchValue, displayInactiveVariants)
            .then((result) => {
              if (result?.articleSearch) {
                setRows(result.articleSearch)
              }
            })
            .finally(() => {
              setIsLoading(false)
              setTimeout(() => {
                if (searchRef.current !== null) {
                  searchRef.current.focus()
                }
              }, 100)
            })
        }
      }
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <TextField
            label="Suche"
            inputRef={searchRef}
            placeholder="Suche nach Artikelnummer oder Name"
            onKeyUp={handleSearchCommit}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayInactiveVariants}
                  onChange={(_, checked) => {
                    setDisplayInactiveVariants(checked)
                    const searchValue = searchRef.current?.value
                    if (
                      searchValue !== undefined &&
                      searchValue !== null &&
                      searchValue !== ""
                    ) {
                      setIsLoading(true)
                      articleSearch(searchValue, checked)
                        .then((result) => {
                          if (result?.articleSearch) {
                            setRows(result.articleSearch)
                          }
                        })
                        .finally(() => {
                          setIsLoading(false)
                          setTimeout(() => {
                            if (searchRef.current !== null) {
                              searchRef.current.focus()
                            }
                          }, 100)
                        })
                    }
                  }}
                />
              }
              label="Inaktive Artikel anzeigen (Artgr = 99)"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ height: "78vh", width: "100%" }}>
            <DataGridPro
              rows={rows}
              onRowClick={(params) => {
                navigate(`/editArticle/${params.id}`)
              }}
              getRowId={(row) => row.bdb}
              columns={columns}
              localeText={dataGridDEde}
              loading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ArticleSearchPage
