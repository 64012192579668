import CloseIcon from "@mui/icons-material/Close"
import { Box, IconButton } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LicenseInfo } from "@mui/x-license-pro"
import { useAppSelector } from "app/hooks"
import { selectUser } from "app/userSlice"
import Navbar from "components/Navbar/Navbar"
import { ADMIN, hasPrivilege } from "constants/privileges"
import "dayjs/locale/de"
import { SnackbarProvider, closeSnackbar } from "notistack"
import ArticleEditPage from "pages/ArticleEditPage/ArticleEditPage"
import ArticleSearchPage from "pages/ArticleSearchPage/ArticleSearchPage"
import BoxEdit from "pages/BoxEdit/BoxEdit"
import BoxStatistic from "pages/BoxStatistic/BoxStatistic"
import { CouponListPage } from "pages/CouponListPage/CouponListPage"
import CustomerSearchPage from "pages/CusomterSearchPage/CustomerSearchPage"
import CustomerEditPage from "pages/CustomerEditPage/CustomerEditPage"
import { DriverAppAdminPanelPage } from "pages/DriverAppAdminPanelPage/DriverAppAdminPanelPage"
import EmployeeStatsPage from "pages/EmployeeStatsPage/EmployeeStatsPage"
import LoginPage from "pages/LoginPage/LoginPage"
import { OrderDetailsPage } from "pages/OrderDetailsPage/OrderDetailsPage"
import OrderList from "pages/OrderList/OrderList"
import { OrderSearchPage } from "pages/OrderSearchPage/OrderSearchPage"
import PackErrorPage from "pages/PackErrorPage/PackErrorPage"
import PackageStatsPage from "pages/PackageStatsPage/PackageStatsPage"
import PerformancePage from "pages/PerformancePage/PerformancePage"
import PrintKistenzettelPage from "pages/PrintKistenzettelPage/PrintKistenzettelPage"
import PrintLabelPage from "pages/PrintLabelPage/PrintLabelPage"
import RabenPage from "pages/RabenPage/RabenPage"
import { RefundListPage } from "pages/RefundListPage/RefundListPage"
import Rml from "pages/Rml/Rml"
import RoundCreatePage from "pages/RoundCreatePage/RoundCreatePage"
import RoundOrderPage from "pages/RoundOrderPage/RoundOrderPage"
import RoundOrderPrintingPage from "pages/RoundOrderPrintingPage/RoundOrderPrintingPage"
import RoundOverviewPage from "pages/RoundOverviewPage/RoundOverviewPage"
import RoundsStatisticPage from "pages/RoundsStatisticPage/RoundsStatisticPage"
import RsLogisticPage from "pages/RsLogisticPage/RsLogisticPage"
import SalesStatsPage from "pages/SalesStatsPage/SalesStatsPage"
import SortOrderPage from "pages/SortOrderPage/SortOrderPage"
import SupplierOrderedItemsPage from "pages/SupplierOrderedItemsPage/SupplierOrderedItemsPage"
import SuppliersPage from "pages/SuppliersPage/SuppliersPage"
import UserAdministration from "pages/UserAdministration/UserAdministration"
import { useRef } from "react"
import { HashRouter, Route, Routes } from "react-router-dom"
import "utils/dayjsConfig"

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY as string)

const setVersionTitle = () => {
  document.title = `Pflanzmich Intranet - ${process.env.REACT_APP_VERSION}`
}

const App = (): JSX.Element => {
  setVersionTitle()
  const userState = useAppSelector(selectUser)
  const snackRef = useRef<any>(null)
  return (
    <>
      <SnackbarProvider
        maxSnack={6}
        ref={snackRef}
        action={(snackbarId) => (
          <IconButton
            aria-label="delete-snackItem"
            onClick={() => closeSnackbar(snackbarId)}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <HashRouter>
            {userState.token === "" && <LoginPage />}
            {userState.token !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <Box sx={{ position: "fixed", top: 0, zIndex: 1100 }}>
                  <Navbar />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    marginTop: "110px",
                    padding: 2,
                  }}
                >
                  <Routes>
                    <Route path="/" element={<RoundsStatisticPage />} />
                    <Route path="/leistung" element={<PerformancePage />} />
                    <Route path="/lieferanten" element={<SuppliersPage />} />
                    <Route path="/versandfehler" element={<PackErrorPage />} />
                    <Route path="/cartonInfo" element={<BoxStatistic />} />
                    <Route path="/cartonEdit" element={<BoxEdit />} />
                    <Route
                      path="/roundOverview"
                      element={<RoundOverviewPage />}
                    />
                    <Route path="/roundCreate" element={<RoundCreatePage />} />
                    <Route path="/roundOrders" element={<RoundOrderPage />} />
                    <Route
                      path="/printSupplierOrders"
                      element={<RoundOrderPrintingPage />}
                    />
                    <Route
                      path="/printCustomerOrders"
                      element={<PrintKistenzettelPage />}
                    />
                    <Route path="/printLabels" element={<PrintLabelPage />} />
                    <Route path="/raben" element={<RabenPage />} />
                    <Route path="/rml" element={<Rml />} />
                    <Route
                      path="/packagestats"
                      element={<PackageStatsPage />}
                    />
                    <Route
                      path="/roundTrack"
                      element={<SupplierOrderedItemsPage />}
                    />
                    <Route path="/sortOrders" element={<SortOrderPage />} />
                    <Route
                      path="/searchArticle"
                      element={<ArticleSearchPage />}
                    />
                    <Route path="editArticle">
                      <Route path=":bdb" element={<ArticleEditPage />} />
                    </Route>

                    <Route path="/orderList" element={<OrderList />} />
                    <Route path="/rs" element={<RsLogisticPage />} />
                    {hasPrivilege(userState.user.privileges, ADMIN) && (
                      <Route
                        path="/userAdministration"
                        element={<UserAdministration />}
                      />
                    )}
                    <Route path="/statistic" element={<SalesStatsPage />} />
                    <Route
                      path="/employeeStats"
                      element={<EmployeeStatsPage />}
                    />

                    <Route
                      path="/searchCustomer"
                      element={<CustomerSearchPage />}
                    />
                    <Route path="editCustomer">
                      <Route
                        path=":customerID"
                        element={<CustomerEditPage />}
                      />
                    </Route>
                    <Route
                      path="/orderDetails/:orderID"
                      element={<OrderDetailsPage />}
                    />
                    <Route path="/searchOrder" element={<OrderSearchPage />} />
                    <Route path="/refundList" element={<RefundListPage />} />
                    <Route path="/couponlist" element={<CouponListPage />} />
                    <Route
                      path="/driverAppAdminPanel"
                      element={<DriverAppAdminPanelPage />}
                    />
                  </Routes>
                </Box>
              </Box>
            )}
          </HashRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </>
  )
}

export default App
