import { DateRange } from "@mui/lab/DateRangePicker"
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import {
  DataGridPro,
  GridColDef,
  GridColumnGroupingModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { dataGridDEde } from "constants/dataGridLocale"
import { Dayjs } from "dayjs"
import { EmployeeStats } from "generated/graphql"
import EmployeeStatsEditTagDialog from "pages/EmployeeStatsEditTagDialog/EmployeeStatsEditTagDialog"
import { fetchEmployeeStats } from "queries/fetchEmployeeStats"
import { useEffect, useMemo, useState } from "react"
import { germanDateFormatter } from "utils/datefmt"
import dayjs from "utils/dayjsConfig"
import { formatDateFromDayjs } from "utils/formatDate"
import { shortcutsItems } from "utils/shortcutItems"

export const leftPad = (v: number): string => {
  if (v < 10) {
    return "0" + v.toString()
  }
  return "" + v.toString()
}

export const formatTime = (value: number): string => {
  let hours = leftPad(Math.floor(value / 60))
  let mins = leftPad(value % 60)
  return hours + ":" + mins
}

export const formatPct = (value: number): string => {
  if (value < 0) {
    return "-"
  }
  return value.toFixed(2)
}

const isBetween = (
  filterDate: dayjs.Dayjs,
  start: string,
  end: string
): boolean => {
  const startDate = dayjs(start)
  const endDate = dayjs(end)
  return !filterDate.isBefore(startDate) && !filterDate.isAfter(endDate)
}

const calcPerHourRate = (count: number, mins: number): number => {
  if (count === 0 || mins === 0) {
    return -1
  }
  if (mins < 60) {
    return count
  }
  return count / Math.round(mins / 60)
}
function getDistinctValues(numbers: number[]): number[] {
  const distinctSet = new Set(numbers)
  return Array.from(distinctSet)
}
function calculateStats(numbers: number[]): StatsDistribution {
  if (numbers.length === 0) {
    return { median: 0, standardDeviation: 0, min: 0, max: 0 }
  }
  const unfilteredSortedNumbers = [...numbers].sort((a, b) => a - b)
  const min: number = unfilteredSortedNumbers[0]
  const max: number =
    unfilteredSortedNumbers[unfilteredSortedNumbers.length - 1]
  const distinctUnfiltered = getDistinctValues(unfilteredSortedNumbers)
  if (distinctUnfiltered.length <= 10) {
    return { median: 0, standardDeviation: 0, min: 0, max: 0 }
  }
  const outlierThreshold = 3
  const filteredNumbers = numbers.filter(
    (num) =>
      num >= distinctUnfiltered[outlierThreshold] &&
      num <= distinctUnfiltered[distinctUnfiltered.length - outlierThreshold]
  )

  const sortedNumbers = [...filteredNumbers].sort((a, b) => a - b)
  const length = sortedNumbers.length

  if (length === 0) {
    return { median: 0, standardDeviation: 0, min: 0, max: 0 }
  }

  // Calculate median
  let median: number | null
  if (length % 2 === 0) {
    const middleIndex = length / 2
    median = (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2
  } else {
    const middleIndex = Math.floor(length / 2)
    median = sortedNumbers[middleIndex]
  }

  // Calculate standard deviation
  const mean = sortedNumbers.reduce((sum, num) => sum + num, 0) / length
  const squaredDifferences = sortedNumbers.map((num) => (num - mean) ** 2)
  const variance =
    squaredDifferences.reduce((sum, squaredDiff) => sum + squaredDiff, 0) /
    length
  const standardDeviation = Math.sqrt(variance)

  return { median, standardDeviation, min, max }
}

type StatsEntry = {
  // personal
  email: string
  name: string
  firstName: string
  lastName: string
  tags: string[]

  // time tracking
  totalTime: number

  // summaries
  summaryActivityCount: number
  summaryAnswerCount: number
  summaryEmailCount: number
  summaryChatCount: number
  summaryPrivateNoteCount: number
  summaryGoodRatingCount: number
  summaryOkRatingCount: number
  summaryBadRatingCount: number
  activityPerHour: number
  answerPerHour: number

  // ticket (a.k.a freshDESK)
  ticketWorkedCount: number
  ticketResolvedCount: number
  ticketClosedCount: number

  ticketBadRatingCount: number
  ticketOkRatingCount: number
  ticketGoodRatingCount: number

  // chat (a.k.a freshCHAT)
  chatAssignedCount: number
  chatResolvedCount: number
  chatMessagesCount: number

  chatGoodRatingCount: number
  chatOkRatingCount: number
  chatBadRatingCount: number
}

type StatsDistribution = {
  median: number
  standardDeviation: number
  min: number
  max: number
}

enum ticketStatus {
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
}

const reduceStats = (
  allStats: EmployeeStats[],
  fromDate: string,
  toDate: string
) => {
  const reducedStats = allStats
    .map((s: EmployeeStats) => reduceEmployeeStats(s, fromDate, toDate))
    .filter((s: StatsEntry) => hasBeenActive(s) && s.name !== "Gesamt")
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })

  activityStats = calculateStats(
    reducedStats.map((rs) => rs.summaryActivityCount)
  )
  answerStats = calculateStats(reducedStats.map((rs) => rs.summaryAnswerCount))
  emailStats = calculateStats(reducedStats.map((rs) => rs.summaryEmailCount))
  chatStats = calculateStats(reducedStats.map((rs) => rs.summaryChatCount))
  noteStats = calculateStats(
    reducedStats.map((rs) => rs.summaryPrivateNoteCount)
  )
  goodRatingStats = calculateStats(
    reducedStats.map((rs) => rs.ticketGoodRatingCount)
  )
  okRatingStats = calculateStats(
    reducedStats.map((rs) => rs.ticketOkRatingCount)
  )
  badRatingStats = calculateStats(
    reducedStats.map((rs) => rs.ticketBadRatingCount)
  )
  activityPerHourStats = calculateStats(
    reducedStats.map((rs) => rs.activityPerHour)
  )
  answerPerHourStats = calculateStats(
    reducedStats.map((rs) => rs.answerPerHour)
  )
  ticketsWorkedStats = calculateStats(
    reducedStats.map((rs) => rs.ticketWorkedCount)
  )
  ticketsResolvedStats = calculateStats(
    reducedStats.map((rs) => rs.ticketResolvedCount)
  )
  ticketsClosedStats = calculateStats(
    reducedStats.map((rs) => rs.ticketClosedCount)
  )

  return reducedStats
}

let activityStats: StatsDistribution,
  answerStats: StatsDistribution,
  emailStats: StatsDistribution,
  chatStats: StatsDistribution,
  noteStats: StatsDistribution,
  goodRatingStats: StatsDistribution,
  okRatingStats: StatsDistribution,
  badRatingStats: StatsDistribution,
  activityPerHourStats: StatsDistribution,
  answerPerHourStats: StatsDistribution,
  ticketsWorkedStats: StatsDistribution,
  ticketsResolvedStats: StatsDistribution,
  ticketsClosedStats: StatsDistribution

const reduceEmployeeStats = (
  stats: EmployeeStats,
  fromDate: string,
  toDate: string
): StatsEntry => {
  const finishedTickets = stats.tickets
    .filter(
      (t) =>
        t.status === ticketStatus.Resolved || t.status === ticketStatus.Closed
    )
    .map((t) => t.id)

  const filteredRatings = stats.ratings.filter((r) => {
    const ratingDate = dayjs(r.updatedAt)
    return isBetween(ratingDate, fromDate, toDate)
  })

  let totalMins = 0
  let emailCount = 0
  let finishedChats = new Set<string>()
  let noteCount = 0
  stats.statsHistory.forEach((day) => {
    totalMins += day.timeTrackingDuration
    day.conversations.forEach((c) => {
      if (c.isEmail) {
        emailCount++
      }
      if (finishedTickets.includes(c.ticketId)) {
        if (!c.isEmail) {
          finishedChats.add(c.ticketId)
        }
      }
      if (c.private) {
        noteCount++
      }
    })
  })
  const finishedChatsCount = finishedChats.size

  const summaryChatCount = finishedChatsCount + stats.chatResolvedCount
  const summaryAnswerCount = emailCount + summaryChatCount
  const summaryActivityCount = noteCount + summaryChatCount + emailCount

  const ticketGoodRatingCount = filteredRatings.filter(
    (r) => r.rating > 0
  ).length
  const ticketOkRatingCount = filteredRatings.filter(
    (r) => r.rating === 0
  ).length
  const ticketBadRatingCount = filteredRatings.filter(
    (r) => r.rating < 0
  ).length

  const chatGoodRatingCount = stats.chatRatings
    .filter((r) => r.rating > 3)
    .reduce((acc, r) => acc + r.count, 0)
  const chatOkRatingCount = stats.chatRatings
    .filter((r) => r.rating === 3)
    .reduce((acc, r) => acc + r.count, 0)
  const chatBadRatingCount = stats.chatRatings
    .filter((r) => r.rating > 0 && r.rating < 3) // ignore 0 as it indicates missing value
    .reduce((acc, r) => acc + r.count, 0)

  return {
    email: stats.email,
    tags: stats.tags,
    name: stats.askDanteFirstName + " " + stats.askDanteLastName,
    firstName: stats.askDanteFirstName,
    lastName: stats.askDanteLastName,
    totalTime: totalMins,
    summaryActivityCount: summaryActivityCount,
    summaryAnswerCount: summaryAnswerCount,
    summaryEmailCount: emailCount,
    summaryChatCount: summaryChatCount,
    summaryPrivateNoteCount: noteCount,
    summaryGoodRatingCount: ticketGoodRatingCount + chatGoodRatingCount,
    summaryOkRatingCount: ticketOkRatingCount + chatOkRatingCount,
    summaryBadRatingCount: ticketBadRatingCount + chatBadRatingCount,
    activityPerHour: calcPerHourRate(summaryActivityCount, totalMins),
    answerPerHour: calcPerHourRate(summaryAnswerCount, totalMins),
    ticketWorkedCount: stats.workedCount,
    ticketResolvedCount: stats.resolvedCount,
    ticketClosedCount: stats.closedCount,
    ticketBadRatingCount: ticketBadRatingCount,
    ticketOkRatingCount: ticketOkRatingCount,
    ticketGoodRatingCount: ticketGoodRatingCount,
    chatAssignedCount: stats.chatAssignedCount,
    chatResolvedCount: stats.chatResolvedCount,
    chatMessagesCount: stats.chatMessagesCount,
    chatGoodRatingCount: chatGoodRatingCount,
    chatOkRatingCount: chatOkRatingCount,
    chatBadRatingCount: chatBadRatingCount,
  }
}

const hasBeenActive = (s: StatsEntry): boolean => {
  return (
    s.chatMessagesCount > 0 ||
    s.chatResolvedCount > 0 ||
    s.ticketClosedCount > 0 ||
    s.ticketResolvedCount > 0 ||
    s.totalTime > 0
  )
}

const colorCodingDesc = (stats: StatsDistribution, value: number): string => {
  return colorCoding(stats, value, false)
}

const colorCodingAsc = (stats: StatsDistribution, value: number): string => {
  return colorCoding(stats, value, true)
}

const colorCoding = (
  stats: StatsDistribution,
  value: number,
  asc: boolean
): string => {
  if (!stats || stats.median === 0) {
    return ""
  }
  if (value < stats.median - stats.standardDeviation) {
    if (asc) {
      return "error.main"
    } else {
      return "success.main"
    }
  }
  if (value > stats.median + stats.standardDeviation) {
    if (asc) {
      return "success.main"
    } else {
      return "error.main"
    }
  }
  return ""
}

const columns: GridColDef<StatsEntry>[] = [
  {
    field: "tags",
    headerName: "Tags",
    width: 150,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "name",
    headerName: "Name",
    width: 170,
    editable: false,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "totalTime",
    headerName: "Arbeitsdauer",
    valueFormatter: (params) => formatTime(params.value),
    width: 120,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "summaryActivityCount",
    headerName: "Aktivitäten",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(activityStats, params.row.summaryActivityCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryAnswerCount",
    headerName: "Antworten",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(answerStats, params.row.summaryAnswerCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryEmailCount",
    headerName: "Emails",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(emailStats, params.row.summaryEmailCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryChatCount",
    headerName: "Chats",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(chatStats, params.row.summaryChatCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryPrivateNoteCount",
    headerName: "Private Notizen",
    width: 120,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(noteStats, params.row.summaryPrivateNoteCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryGoodRatingCount",
    headerName: "Gut",
    width: 50,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            goodRatingStats,
            params.row.summaryGoodRatingCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryOkRatingCount",
    headerName: "OK",
    width: 50,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(okRatingStats, params.row.summaryOkRatingCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "summaryBadRatingCount",
    headerName: "Schlecht",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingDesc(
            badRatingStats,
            params.row.summaryBadRatingCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "activityPerHour",
    headerName: "Aktv. / Stunde",
    description: "Privat Notizen + Antworten / Stunde",
    width: 120,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            activityPerHourStats,
            params.row.activityPerHour
          ),
        }}
      >
        {formatPct(params.value)}
      </Typography>
    ),
  },
  {
    field: "answerPerHour",
    headerName: "Ant. / Stunde",
    description: "Antworten pro Stunde",
    width: 120,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(answerPerHourStats, params.row.answerPerHour),
        }}
      >
        {formatPct(params.value)}
      </Typography>
    ),
  },
  {
    field: "ticketWorkedCount",
    headerName: "Tickets bearbeitet",
    width: 140,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            ticketsWorkedStats,
            params.row.ticketWorkedCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "ticketResolvedCount",
    headerName: "Tickets gelöst",
    width: 120,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            ticketsResolvedStats,
            params.row.ticketResolvedCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "ticketClosedCount",
    headerName: "Tickets geschlossen",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            ticketsClosedStats,
            params.row.ticketClosedCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "ticketGoodRatingCount",
    headerName: "Gut",
    width: 50,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(
            goodRatingStats,
            params.row.ticketGoodRatingCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "ticketOkRatingCount",
    headerName: "OK",
    width: 50,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingAsc(okRatingStats, params.row.ticketOkRatingCount),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "ticketBadRatingCount",
    headerName: "Schlecht",
    width: 80,
    editable: false,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => (
      <Typography
        sx={{
          color: colorCodingDesc(
            badRatingStats,
            params.row.ticketBadRatingCount
          ),
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "chatAssignedCount",
    headerName: "Chat Zugewiesen",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "chatResolvedCount",
    headerName: "Chats gelöst",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "chatMessagesCount",
    headerName: "Chat Nachrichten",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "chatGoodRatingCount",
    headerName: "Chat Gut",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "chatOkRatingCount",
    headerName: "Chat Ok",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "chatBadRatingCount",
    headerName: "Chat Schlecht",
    width: 150,
    editable: false,
    align: "right",
    headerAlign: "right",
  },
]

const columnGroupings: GridColumnGroupingModel = [
  {
    groupId: "Zusammenfassung",
    children: [
      { field: "summaryActivityCount" },
      { field: "summaryAnswerCount" },
      { field: "summaryEmailCount" },
      { field: "summaryChatCount" },
      { field: "summaryPrivateNoteCount" },
    ],
  },
  {
    groupId: "Gesamtbewertung",
    children: [
      { field: "summaryGoodRatingCount" },
      { field: "summaryOkRatingCount" },
      { field: "summaryBadRatingCount" },
    ],
  },
  {
    groupId: "Durchschnittliche Leistung",
    children: [{ field: "activityPerHour" }, { field: "answerPerHour" }],
  },
  {
    groupId: "Freshdesk Statistiken",
    children: [
      { field: "ticketWorkedCount" },
      { field: "ticketResolvedCount" },
      { field: "ticketClosedCount" },
    ],
  },
  {
    groupId: "Freshdesk Bewertungen",
    children: [
      { field: "ticketGoodRatingCount" },
      { field: "ticketOkRatingCount" },
      { field: "ticketBadRatingCount" },
    ],
  },
  {
    groupId: "Freshchat Statistiken",
    children: [
      { field: "chatAssignedCount" },
      { field: "chatResolvedCount" },
      { field: "chatMessagesCount" },
    ],
  },
  {
    groupId: "Freshchat Bewertungen",
    children: [
      { field: "chatGoodRatingCount" },
      { field: "chatOkRatingCount" },
      { field: "chatBadRatingCount" },
    ],
  },
]

const legendRows = [
  {
    name: "Name",
    contents: "Name des Mitarbeiters.",
  },
  {
    name: "Arbeitsdauer",
    contents: "Die Arbeitsstunden laut AskDante. Enthält keine Feiertage.",
  },
  {
    name: "Zusammenfassung",
    contents: "",
  },
  {
    name: "Antworten",
    contents:
      "Zählung jeder gesendeten E-Mail und jedes erfolgreichen Chat-Threads.",
  },
  {
    name: "Emails",
    contents: "Zählung jeder gesendeten E-Mail. (Freshchat)",
  },
  {
    name: "Chats",
    contents:
      "Zählung aller Chat-Thread, die zur Schließung eines Tickets führten (Freshdesk + Freshchat)",
  },
  {
    name: "Private Notizen",
    contents: "Zählung jeder gesendete Private Notiz. (Freshchat)",
  },
  {
    name: "Gesamtbewertung",
    contents: "",
  },
  {
    name: "Gut",
    contents: "Anzahl der guten Bewertungen. (Freshdesk + Freshchat)",
  },
  {
    name: "OK",
    contents: "Anzahl der OK Bewertungen. (Freshdesk + Freshchat)",
  },
  {
    name: "Schlecht",
    contents: "Anzahl der schlechte Bewertungen. (Freshdesk + Freshchat)",
  },
  {
    name: "Durchschnittliche Leistung",
    contents: "",
  },
  {
    name: "Akiv. / Stunde",
    contents:
      "Berechnet durch (Anzahl der E-Mails + Anzahl der erfolgreichen Chats + private Notizen} / (volle Arbeitsstunden)",
  },
  {
    name: "Ant. / Stunde",
    contents:
      "Berechnet durch (Anzahl der E-Mails + Anzahl der erfolgreichen Chats} / (volle Arbeitsstunden)",
  },
  {
    name: "Freshdesk Statistiken",
    contents: "",
  },
  {
    name: "Tickets bearbeitet *",
    contents:
      "Anzahl aller bearbeiteten Tickets einschließlich aller aktuellen Status. (Freshdesk)",
  },
  {
    name: "Tickets gelöst *",
    contents:
      "Anzahl aller bearbeiteten Tickets, die den Status gelöst haben. (Freshdesk)",
  },
  {
    name: "Tickets geschlossen *",
    contents:
      "Anzahl aller bearbeiteten Tickets, die den Status geschlossen haben. (Freshdesk)",
  },
  {
    name: "Gut",
    contents: "Anzahl der guten Bewertungen. (Freshdesk)",
  },
  {
    name: "OK",
    contents: "Anzahl der OK Bewertungen. (Freshdesk)",
  },
  {
    name: "Schlecht",
    contents: "Anzahl der schlechte Bewertungen. (Freshdesk)",
  },
  {
    name: "Freshchat Statistiken",
    contents: "",
  },
  {
    name: "Chat zugewiesen *",
    contents: "Anzahl aller dem Benutzer zugewiesenen Chat-Themen. (Freshchat)",
  },
  {
    name: "Chats gelöst *",
    contents:
      "Anzahl aller Chat-Themen, die vom Benutzer gelöst wurden.. (Freshchat)",
  },
  {
    name: "Chat Nachrichten *",
    contents:
      "Anzahl aller vom Benutzer gesendeten Chat-Nachrichten. (Freshchat)",
  },
  {
    name: "Chat Gut",
    contents: "Anzahl der guten Bewertungen. (Freshchat)",
  },
  {
    name: "Chat OK *",
    contents: "Anzahl der OK Bewertungen. (Freshchat)",
  },
  {
    name: "Chat Schlecht *",
    contents: "Anzahl der schlechte Bewertungen. (Freshchat)",
  },
  {
    name: "",
    contents: "",
  },
  {
    name: "*",
    contents:
      "Diese Werte werden nur einmal pro Tag für den Vortag aktualisiert",
  },
]

const calculateSummary = (
  filteredEmails: string[],
  tags: string[],
  employeeStats: StatsEntry[]
): StatsEntry => {
  var summary: StatsEntry = {
    name: "Gesamt",
    tags: [],
    totalTime: 0,
    email: "",
    firstName: "",
    lastName: "",
    summaryActivityCount: 0,
    summaryAnswerCount: 0,
    summaryEmailCount: 0,
    summaryChatCount: 0,
    summaryPrivateNoteCount: 0,
    summaryGoodRatingCount: 0,
    summaryOkRatingCount: 0,
    summaryBadRatingCount: 0,
    activityPerHour: 0,
    answerPerHour: 0,
    ticketWorkedCount: 0,
    ticketResolvedCount: 0,
    ticketClosedCount: 0,
    ticketBadRatingCount: 0,
    ticketOkRatingCount: 0,
    ticketGoodRatingCount: 0,
    chatAssignedCount: 0,
    chatResolvedCount: 0,
    chatMessagesCount: 0,
    chatGoodRatingCount: 0,
    chatOkRatingCount: 0,
    chatBadRatingCount: 0,
  }

  for (let i = 0; i < employeeStats.length; i++) {
    const tagMatch =
      tags.length === 0 || employeeStats[i].tags.some((r) => tags.includes(r))
    const filterMatch =
      filteredEmails.length === 0 ||
      filteredEmails.includes(employeeStats[i].email)
    if (tagMatch || filterMatch) {
      summary.totalTime += employeeStats[i].totalTime
      summary.summaryActivityCount += employeeStats[i].summaryActivityCount
      summary.summaryAnswerCount += employeeStats[i].summaryAnswerCount
      summary.summaryEmailCount += employeeStats[i].summaryEmailCount
      summary.summaryChatCount += employeeStats[i].summaryChatCount
      summary.summaryPrivateNoteCount +=
        employeeStats[i].summaryPrivateNoteCount
      summary.summaryGoodRatingCount += employeeStats[i].summaryGoodRatingCount
      summary.summaryOkRatingCount += employeeStats[i].summaryOkRatingCount
      summary.summaryBadRatingCount += employeeStats[i].summaryBadRatingCount
      summary.activityPerHour += employeeStats[i].activityPerHour
      summary.answerPerHour += employeeStats[i].answerPerHour
      summary.ticketWorkedCount += employeeStats[i].ticketWorkedCount
      summary.ticketResolvedCount += employeeStats[i].ticketResolvedCount
      summary.ticketClosedCount += employeeStats[i].ticketClosedCount
      summary.ticketBadRatingCount += employeeStats[i].ticketBadRatingCount
      summary.ticketOkRatingCount += employeeStats[i].ticketOkRatingCount
      summary.ticketGoodRatingCount += employeeStats[i].ticketGoodRatingCount
      summary.chatAssignedCount += employeeStats[i].chatAssignedCount
      summary.chatResolvedCount += employeeStats[i].chatResolvedCount
      summary.chatMessagesCount += employeeStats[i].chatMessagesCount
      summary.chatGoodRatingCount += employeeStats[i].chatGoodRatingCount
      summary.chatOkRatingCount += employeeStats[i].chatOkRatingCount
      summary.chatBadRatingCount += employeeStats[i].chatBadRatingCount
    }
  }
  summary.activityPerHour =
    summary.summaryActivityCount / Math.round(summary.totalTime / 60)
  summary.answerPerHour =
    summary.summaryAnswerCount / Math.round(summary.totalTime / 60)
  return summary
}

const EmployeeStatsPage = () => {
  const today = dayjs()
  const [tagEditIsOpen, setTagEditIsOpen] = useState(false)
  const [loading, setloading] = useState(false)
  const [employeeStats, setEmployeeStats] = useState<StatsEntry[]>([])
  const [lastUpdate, setLastUpdate] = useState<string>("Keine update vorhanden")
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    today.subtract(1, "day"),
    today,
  ])
  const [tags, setTags] = useState<string[]>([])
  const [filteredEmails, setFilteredEmails] = useState<string[]>([])
  const apiRef = useGridApiRef()

  useEffect(() => {
    setloading(true)
    const today = dayjs()
    let searchTo = dateRange[1]?.isAfter(today) ? today : dateRange[1]
    let searchFrom = dateRange[0]?.isAfter(searchTo) ? searchTo : dateRange[0]
    const fromDate = formatDateFromDayjs(searchFrom, today) + " 00:00:00"
    const toDate = formatDateFromDayjs(searchTo, today) + " 23:59:59"

    if (!fromDate || !toDate) return
    if (fromDate === "" || toDate === "") return

    fetchEmployeeStats(fromDate, toDate)
      .then((res) => {
        if (res && res.employeeStats) {
          const reducedStats = reduceStats(
            res.employeeStats.stats,
            fromDate,
            toDate
          )
          setEmployeeStats(reducedStats)
          if (res.employeeStats.employeeStatsOutput) {
            let localTime = dayjs
              .utc(res.employeeStats.employeeStatsOutput)
              .tz("Europe/Berlin")
            setLastUpdate(germanDateFormatter.format(localTime.toDate()))
          }
        }
      })
      .finally(() => {
        setloading(false)
      })
  }, [dateRange])

  const pinnedRows = useMemo(() => {
    return { top: [calculateSummary(filteredEmails, tags, employeeStats)] }
  }, [tags, employeeStats, filteredEmails])

  return (
    <Container maxWidth={false}>
      <Grid container justifyContent={"center"} spacing={1}>
        <Grid item xs={4}>
          <Typography>Letzes Update: {lastUpdate}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => setTagEditIsOpen(true)}
            sx={{ height: "100%" }}
          >
            Mitarbeiter Tags verwalten
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <Box sx={{ width: 500 }}>
              <DateRangePicker
                slotProps={{
                  actionBar: {
                    actions: ["accept"],
                  },
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                onAccept={(t) => setDateRange(t)}
                localeText={{ start: "Von", end: "Bis" }}
                defaultValue={dateRange}
                disableFuture={true}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              height: "80vh",
              width: "100%",
              "& .rating": {
                backgroundColor: "ghostWhite",
              },
            }}
          >
            <DataGridPro
              pinnedRows={pinnedRows}
              getRowId={(row) => row.email}
              rows={employeeStats}
              columns={columns}
              apiRef={apiRef}
              onFilterModelChange={(filterModel) => {
                console.log(filterModel)
                if (filterModel.items.length > 0) {
                  const tags = filterModel.items
                    .filter((item) => item.field === "tags")
                    .map((item) => item.value)
                  setTags(tags as string[])
                } else {
                  setTags([])
                }
              }}
              columnGroupingModel={columnGroupings}
              experimentalFeatures={{ columnGrouping: true }}
              loading={loading}
              localeText={dataGridDEde}
              sortingOrder={["desc", "asc"]}
            />
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 5, marginBottom: 5 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Spalte</TableCell>
              <TableCell>Inhalt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {legendRows.map((row, index) => (
              <TableRow
                key={row.name + index.toString()}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.contents}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmployeeStatsEditTagDialog
        isOpen={tagEditIsOpen}
        setOpen={setTagEditIsOpen}
      />
    </Container>
  )
}
export default EmployeeStatsPage
