import { store } from "app/store"
import { AuthRequest } from "generated/graphql"
import { DocumentNode } from "graphql"
import { setLoading } from "./loadingSlice"
import { qSnack } from "./snackSlice"
import { logout } from "./userSlice"

let BASE_URL = ""

if (process.env.NODE_ENV === "development") {
  //BASE_URL = "https://main-pflanzmich.testingserver3.qubidu.com"
}

// additonal fetch functions for public route login which is rest
export const fetchLogin = async (req: AuthRequest) => {
  const response = await fetch(BASE_URL + "/user/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: req.username,
      password: req.password,
    }),
  })
  return await response.json()
}

export const fetchGQL = async <TData = any, TVariables = Record<string, any>>(
  query: DocumentNode,
  variables: TVariables
): Promise<TData | undefined> => {
  const token = store.getState().userState.token
  if (token === "") {
    store.dispatch(logout())
    return undefined
  }
  store.dispatch(setLoading(true))
  const response = await fetch(BASE_URL + "/gql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query: query.loc?.source.body,
      variables,
    }),
  })
  store.dispatch(setLoading(false))

  if (response.status === 401) {
    // user will be rerouted to login automatically the store knows best
    store.dispatch(logout())
    return undefined
  }
  const res = await response.json()
  if (res.errors) {
    const msgs = res.errors.map((e: any) => e.message)
    store.dispatch(
      qSnack({
        msg:
          "Bei der Abfrage ist etwas schiefgelaufen. Bitte bei einem Administrator melden! " +
          msgs,
        severity: "error",
      })
    )
  }

  return res.data as TData
}
