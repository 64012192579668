import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { LineChart, LineSeriesType } from "@mui/x-charts"
import dayjs from "dayjs"
import { ArticlePriceHistory } from "generated/graphql"
import { useEffect, useState } from "react"

export interface ArticlePriceHistoryProps {
  priceHistory: ArticlePriceHistory[]
}

const getDates = (data: ArticlePriceHistory[]): string[] => {
  const dates = new Set(
    data.map((d) => dayjs(d.createdAt).format("YYYY-MM-DD")).sort()
  )
  dates.add(dayjs().format("YYYY-MM-DD")) // add current date as endpoint
  return Array.from(dates)
}

const dateMatches = (date: string, dateTime: string): boolean => {
  return dayjs(dateTime).format("YYYY-MM-DD") === date
}

const getLineChartSeries = (
  prices: ArticlePriceHistory[],
  dates: string[]
): LineSeriesType[] => {
  const series: LineSeriesType[] = []
  const supplierNames = Array.from(new Set(prices.map((p) => p.supplierName)))
  supplierNames.forEach((supplierName) => {
    const supplierPriceHistory = prices.filter(
      (p) => p.supplierName === supplierName
    )
    const supplierData: (number | null)[] = []
    var lastPrice: number | null
    dates.forEach((date) => {
      const dayPrices = supplierPriceHistory
        .filter((ph) => dateMatches(date, ph.createdAt))
        .sort(
          (p1, p2) => dayjs(p2.createdAt).unix() - dayjs(p1.createdAt).unix()
        )
      if (dayPrices.length > 0) {
        var price = parseInt(dayPrices[0].price) / 100 // use close price
        if (dayPrices[0].action === "DELETE") {
          lastPrice = null
        } else {
          lastPrice = price
        }
        supplierData.push(price)
      } else {
        if (dateMatches(date, dayjs().format("YYYY-MM-DD"))) {
          supplierData.push(lastPrice) // extend price to end of graph
        } else {
          supplierData.push(null)
        }
      }
    })
    series.push({
      data: supplierData,
      curve: "stepAfter",
      connectNulls: true,
      label: supplierName,
      type: "line",
    })
  })
  return series
}

const ArticleEditPagePriceHistory = (
  props: ArticlePriceHistoryProps
): JSX.Element => {
  const { priceHistory } = props
  const [xAxis, setXaxis] = useState<string[]>([])
  const [series, setSeries] = useState<LineSeriesType[]>([])
  const [range, setRange] = useState<number>(3)

  useEffect(() => {
    const dates = getDates(priceHistory)
    setXaxis(dates)
    setSeries(getLineChartSeries(priceHistory, dates))
  }, [priceHistory, range])

  const startDate = (): Date => {
    const selectedDate = dayjs().add(-1 * range, "month")
    const earliestData = dayjs(xAxis[0])
    if (range === 99 || (earliestData && earliestData.isAfter(selectedDate))) {
      return earliestData.toDate()
    }
    return selectedDate.toDate()
  }

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        sx={{ marginTop: 3 }}
      >
        Preis Historie
      </Typography>
      <LineChart
        xAxis={[
          {
            data: xAxis.map((d) => Date.parse(d)),
            valueFormatter: (value) => dayjs(value).format("DD-MM-YYYY"),
            scaleType: "time",
            min: startDate(),
          },
        ]}
        series={series}
        height={400}
        margin={{ top: 10, bottom: 20 }}
      />
      <Box display="flex" justifyContent="flex-end">
        <FormControl sx={{ marginBottom: 2, marginTop: 5 }}>
          <InputLabel id="range-select-label">Zeitspanne</InputLabel>
          <Select
            id="range-select"
            labelId="range-select-label"
            label="Zeitspanne"
            value={range.toString()}
            onChange={(e: SelectChangeEvent) => {
              setRange(Number(e.target.value))
            }}
          >
            <MenuItem key="1-month" value={1}>
              1 Monat
            </MenuItem>
            <MenuItem key="3-months" value={3}>
              3 Monate
            </MenuItem>
            <MenuItem key="6-months" value={6}>
              6 Monate
            </MenuItem>
            <MenuItem key="12-months" value={12}>
              12 Monate
            </MenuItem>
            <MenuItem key="all" value={99}>
              Alle
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default ArticleEditPagePriceHistory
