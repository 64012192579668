import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { qSnack } from "app/snackSlice"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs, { Dayjs } from "dayjs"
import {
  OrderListInput,
  RoundOrderItem,
  SendOrderListInput,
} from "generated/graphql"
import { uniq } from "lodash"
import { orderListSend, roundOrderListGet } from "queries/fetchOrderList"
import { orderListPreviewPDF } from "queries/orderListPreviewPDF"
import { supplierRoundInfoSave } from "queries/supplierRoundInfo"
import { KeyboardEvent, useEffect, useState } from "react"
import { base64ToBlob } from "utils/base64ToBlob"
import { downloadBlob } from "utils/downloadBlob"

const getSupplierNames = (
  ids: number[],
  orderItems: RoundOrderItem[]
): string => {
  return orderItems.filter((oi) => ids.includes(oi.supplierID)).join(",")
}

export const groupList = [
  "Alle",
  "Pinneberger",
  "Oldenburger",
  "G24",
  "Lager",
  "Sonstige",
]

export const getGroupName = (
  isStorage: boolean,
  g24: boolean,
  flavour: number
): string[] => {
  const groups: string[] = []
  if (isStorage) {
    groups.push("Lager")
  }
  if (g24) {
    groups.push("G24")
  }
  if (flavour === 0) {
    groups.push("Pinneberger")
  }
  if (flavour === 2) {
    groups.push("Oldenburger")
  }
  if (groups.length === 0) {
    groups.push("Sonstige")
  }
  return groups
}

const OrderList = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const selectedRound = useAppSelector(selectCurrentRound)
  const [orderList, setOrderList] = useState<RoundOrderItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selection, setSelection] = useState<RoundOrderItem[]>([])
  const [currentGroup, setCurrentGroup] = useState<string>("Alle")
  const [selectedNoteText, setSelectedNoteText] = useState<string>("")
  const [selectedCollectionDate, setSelectedCollectionDate] =
    useState<Dayjs | null>()
  const [selectedCollectionTime, setSelectedCollectionTime] =
    useState<Dayjs | null>(null)

  useEffect(() => {
    if (selectedRound) {
      setIsLoading(true)
      roundOrderListGet(selectedRound.round)
        .then((data) => {
          if (data?.roundOrderList) {
            setOrderList(data.roundOrderList.items)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [selectedRound])

  const handleChangeGroup = (event: any) => {
    setSelection([])
    setCurrentGroup(event.target.value as string)
  }

  const handleSendItems = (input: SendOrderListInput) => {
    setIsLoading(true)
    orderListSend(input)
      .then((data) => {
        if (data?.sendOrderList) {
          let names = getSupplierNames(
            input.supplier.map((x) => x.supplierID),
            orderList
          )
          names = uniq(names.split(", ")).join(", ")
          //update orderList
          const msg = input.sendEmail
            ? "Emails versendet: " + names
            : "Fax ans sipgate weitergeleitet(es kommt bei Erfolg eine Email: 1-5 min Wartezeit): " +
              names
          dispatch(qSnack({ msg: msg, severity: "success" }))
          roundOrderListGet(input.round).then((data2) => {
            if (data2?.roundOrderList && data2.roundOrderList.items) {
              setOrderList(data2.roundOrderList.items)
            }
          })
        }
      })
      .finally(() => {
        setSelection([])
        setIsLoading(false)
      })
  }

  const handleSendEmailBulk = () => {
    if (!selectedRound) return
    const items = selection
      .filter((item) => item.sendEmail)
      .map((item) => {
        let date = item.emailSentAt
        if (date === "") {
          date = item.faxSentAt
        }
        return {
          supplierID: item.supplierID,
          date: date,
        }
      })
    handleSendEmail(selectedRound.round, items)
  }

  const handleSendEmail = (round: number, items: OrderListInput[]) => {
    const input: SendOrderListInput = {
      round: round,
      supplier: items,
      sendEmail: true,
      sendFax: false,
    }
    handleSendItems(input)
  }

  const handleSendFaxBulk = () => {
    if (!selectedRound) return
    const items = selection
      .filter((item) => item.sendFax)
      .map((item) => {
        let date = item.emailSentAt
        if (date === "") {
          date = item.faxSentAt
        }
        return {
          supplierID: item.supplierID,
          date: date,
        }
      })
    handleSendFax(selectedRound.round, items)
  }

  const handleSendFax = (round: number, items: OrderListInput[]) => {
    const input: SendOrderListInput = {
      round: round,
      supplier: items,
      sendEmail: false,
      sendFax: true,
    }
    handleSendItems(input)
  }

  const handleSelectionModelChange = (selectionModel: any) => {
    //selection model is an array of selected row ids here routeIDs
    // e.g [1,2,3] this comes from the database
    const selectedOrderList = selectionModel.map((id: any) =>
      orderList.find(
        (item) => item.supplierID + item.emailSentAt + item.faxSentAt === id
      )
    )
    setSelection(selectedOrderList)
  }

  const handleNoteKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      saveNote(dayjs(), "date")
    }
  }

  const saveNote = (e: Dayjs, type: "date" | "time") => {
    setIsLoading(true)
    if (!selectedRound) return
    if (!selection || selection.length === 0) return

    let colDate: string | null = null
    if (type === "date") {
      colDate = e.format("YYYY-MM-DD")
    } else {
      colDate = selectedCollectionDate
        ? selectedCollectionDate.format("YYYY-MM-DD")
        : null
    }

    let colTime: string | null = null
    if (type === "time") {
      colTime = e.format("HH:mm")
    } else {
      colTime = selectedCollectionTime
        ? selectedCollectionTime.format("HH:mm")
        : null
    }

    const supplierIds = Array.from(new Set(selection.map((s) => s.supplierID)))
    supplierIds.forEach((supplierId) => {
      supplierRoundInfoSave({
        in: {
          round: selectedRound.round,
          supplierID: supplierId,
          note: selectedNoteText,
          requiresCollection:
            selectedNoteText == null || selectedNoteText === "",
          collectionDate: colDate,
          collectionTime: colTime,
        },
      }).then((res) => {
        if (!res || res.supplierRoundInfoSave !== true) {
          dispatch(
            qSnack({
              msg: "Notiz konnte nicht gespeichert werden",
              severity: "error",
            })
          )
          return
        }
      })
    })
    setIsLoading(false)
    dispatch(
      qSnack({
        msg: "Notiz gespeichert",
        severity: "success",
      })
    )
  }

  const columns: GridColDef<RoundOrderItem>[] = [
    {
      field: "supplierID",
      headerName: "Kurzwahl",
      width: 100,
    },
    {
      field: "supplierName",
      headerName: "Lieferant",
      width: 200,
      editable: false,
    },
    {
      field: "pdfPath",
      headerName: "Bestellliste",
      width: 200,
      editable: false,
      renderCell: (params) => {
        const handleClick = async (_: any) => {
          if (!selectedRound) return
          let date = params.row.emailSentAt
          if (date === "") {
            date = params.row.faxSentAt
          }
          orderListPreviewPDF(
            selectedRound?.round,
            params.row.supplierID,
            date
          ).then((data) => {
            if (data?.orderListPreviewPDF) {
              const blb = base64ToBlob(
                data.orderListPreviewPDF.pdfBase64,
                "application/pdf"
              )
              const now = dayjs().format("DD.MM.YYYY_HH.mm")
              downloadBlob(
                blb,
                "R" +
                  selectedRound.round.toString() +
                  "_" +
                  params.row.supplierID.toString() +
                  "_" +
                  params.row.supplierName +
                  "_" +
                  now +
                  ".pdf"
              )
            }
          })
        }
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={handleClick}
          >
            PDF Anzeigen
          </Typography>
        )
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
      editable: false,
      renderCell: (params) => {
        const sendEmail = params.row.sendEmail
        const isEmailSent = params.row.emailSentAt !== ""
        const handleClick = () => {
          if (!selectedRound) return
          let date = params.row.emailSentAt
          if (date === "") {
            date = params.row.faxSentAt
          }
          handleSendEmail(selectedRound.round, [
            { supplierID: params.row.supplierID, date: date },
          ])
        }
        return (
          <>
            {sendEmail && params.value && (
              <>
                {!isEmailSent && <CloseIcon color="error" />}
                {isEmailSent && <DoneIcon color="success" />}
                <Button onClick={handleClick} variant="contained" size="small">
                  {params.value}
                </Button>
              </>
            )}
            {(!sendEmail || !params.value) && <div>Akzeptiert keine Email</div>}
          </>
        )
      },
    },
    {
      field: "emailSentAt",
      headerName: "Email gesendet am",
      valueGetter: (params) => {
        return params.row.emailSentAt
          ? dayjs(params.row.emailSentAt).format("DD.MM.YYYY HH:mm")
          : ""
      },
      width: 150,
    },
    {
      field: "fax",
      headerName: "Fax",
      width: 250,
      editable: false,
      renderCell: (params) => {
        const sendFax = params.row.sendFax
        const isFaxSent = params.row.faxSentAt !== ""
        const handleClick = () => {
          if (!selectedRound) return
          handleSendFax(selectedRound.round, [
            { supplierID: params.row.supplierID, date: "" },
          ])
        }
        return (
          <>
            {sendFax && (
              <>
                {!isFaxSent && params.value && <CloseIcon color="error" />}
                {isFaxSent && <DoneIcon color="success" />}
                <Button onClick={handleClick} variant="contained" size="small">
                  {params.value}
                </Button>
              </>
            )}
            {(!sendFax || !params.value) && <div>Akzeptiert kein Fax</div>}
          </>
        )
      },
    },
    {
      field: "faxSentAt",
      headerName: "Fax gesendet am",
      valueGetter: (params) => {
        return params.row.faxSentAt
          ? dayjs(params.row.faxSentAt).format("DD.MM.YYYY HH:mm")
          : ""
      },
      width: 150,
    },
    {
      field: "contacted",
      valueGetter: (params) => {
        return params.row.emailSentAt !== "" || params.row.faxSentAt !== ""
      },
      headerName: "Kontaktiert",
      width: 150,
      editable: false,
      renderCell: (params) => {
        const value =
          params.row.faxSentAt !== "" || params.row.emailSentAt !== ""
        return (
          <Typography
            variant="body2"
            sx={{
              color: value ? "success.main" : "error.main",
            }}
          >
            {value ? "Ja" : "Nein"}
          </Typography>
        )
      },
      sortComparator: (params1, params2, _, __) => {
        if (params1 === params2) return 0
        return params1 ? 1 : -1
      },
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 500,
      editable: false,
    },
    {
      field: "g24",
      headerName: "G24",
      editable: false,
    },
    {
      field: "flavour",
      headerName: "Flavour",
      editable: false,
    },
    {
      field: "inStorage",
      headerName: "Lager",
      editable: false,
    },
  ]

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                component="label"
                fullWidth
                loading={isLoading}
                sx={{ height: "100%" }}
                disabled={
                  selection.filter((item) => item.sendEmail).length === 0
                }
                onClick={handleSendEmailBulk}
              >
                SENDE MAIL (
                {
                  selection.filter((item: RoundOrderItem) => item.sendEmail)
                    .length
                }
                )
              </LoadingButton>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                variant="contained"
                component="label"
                fullWidth
                sx={{ height: "100%" }}
                disabled={selection.filter((item) => item.sendFax).length === 0}
                loading={isLoading}
                onClick={handleSendFaxBulk}
              >
                SENDE FAX ({selection.filter((item) => item.sendFax).length})
              </LoadingButton>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label-group">
                  Gruppieren
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-group"
                  id="demo-simple-select-group"
                  value={currentGroup}
                  label="Gruppieren"
                  onChange={handleChangeGroup}
                >
                  {groupList.map((grp) => (
                    <MenuItem key={grp} value={grp}>
                      {grp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Information den Lieferanten-Bestellung zuweisen mit Enter senden"
            onChange={(e) => {
              setSelectedNoteText(e.target.value)
            }}
            onKeyUp={handleNoteKeyUp}
            onBlur={() => {
              saveNote(dayjs(), "date")
            }}
            disabled={selection.length === 0}
          />
        </Grid>
        <Grid item xs={3}>
          <DatePicker
            label="Abholdatum"
            value={selectedCollectionDate}
            slotProps={{ textField: { fullWidth: true } }}
            onChange={(newDate) => {
              if (newDate) {
                setSelectedCollectionDate(newDate)
                saveNote(newDate, "date")
              }
            }}
            disabled={selection.length === 0}
          />
        </Grid>
        <Grid item xs={2}>
          <TimePicker
            label="Abholzeit"
            slotProps={{ textField: { fullWidth: true } }}
            minTime={dayjs().set("hour", 5)}
            maxTime={dayjs().set("hour", 18)}
            timeSteps={{ minutes: 60 }}
            disabled={selection.length === 0}
            onChange={(e) => {
              if (e) {
                setSelectedCollectionTime(e || null)
                saveNote(e, "time")
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "71vh", width: "100%" }}>
            <DataGridPro
              rows={orderList
                .sort((a, b) => a.supplierName.localeCompare(b.supplierName))
                .filter((item) => {
                  if (currentGroup === "Alle") return true
                  // item includes in current group
                  // an item can be in more than one group
                  const grps = getGroupName(
                    item.inStorage,
                    item.g24,
                    item.flavour
                  )
                  return grps.includes(currentGroup)
                })}
              columns={columns}
              getRowId={(item) =>
                item.supplierID + item.emailSentAt + item.faxSentAt
              }
              localeText={dataGridDEde}
              sortingOrder={["desc", "asc"]}
              loading={isLoading}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionModelChange}
              rowSelectionModel={selection.map(
                (item) => item.supplierID + item.emailSentAt + item.faxSentAt
              )}
              disableRowSelectionOnClick
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default OrderList
