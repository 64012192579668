import { fetchGQL } from "app/api"
import { OrderByIdQuery, OrderByIdQueryVariables } from "generated/graphql"
import { gql } from "graphql-tag"

const ORDER_BY_ID = gql`
  query orderByID($orderID: Int!) {
    orderByID(orderID: $orderID) {
      orderID
      kundnum
      urorder
      datum
      zieldat
      status
      zahlart
      rechnum
      rechsum
      bezahlt
      rabatt
      frachtpausch
      notiz
      custnote
      secretnote
      couponKey
      couponValue
      sh
      roundName
      rue
      rueText
      deliveryAdr {
        id
        company
        anrede
        fullName
        firstName
        lastName
        street
        zipCode
        city
        country
        countryCode
      }
      orderItems {
        ewID
        artnum
        botname
        von
        bis
        stahoeh
        stuvon
        stubis
        anzahl
        stueckpreis
        source
        lager
        bestellt
        etikettiert
        verteilt
        splitstatus
        soldStatus
      }
      packages {
        id
        orderID
        carrier
        ps
        packageNumber
        createdAt
        labelURL
        trackingURL
      }
    }
  }
`

export const orderByID = async (
  orderID: number
): Promise<OrderByIdQuery | undefined> => {
  const variables: OrderByIdQueryVariables = { orderID }
  const data = await fetchGQL<OrderByIdQuery, OrderByIdQueryVariables>(
    ORDER_BY_ID,
    variables
  )
  return data
}
