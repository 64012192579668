import { fetchGQL } from "app/api"
import {
  CustomerOrdersQuery,
  CustomerOrdersQueryVariables,
  CustomerRelatedOrdersQuery,
  CustomerRelatedOrdersQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_RELATED_ORDERS = gql`
  query customerRelatedOrders($oid: Int!) {
    customerRelatedOrders(oid: $oid) {
      orderID
      urorder
      datum
      zieldat
      status
      zahlart
      rechnum
      rechsum
      bezahlt
      frachtpausch
      notiz
      custnote
    }
  }
`

const CUSTOMER_SEARCH = gql`
  query customerOrders($customerID: Int!) {
    customerOrders(customerID: $customerID) {
      orderID
      urorder
      datum
      zieldat
      status
      zahlart
      rechnum
      rechsum
      bezahlt
      frachtpausch
      notiz
      custnote
    }
  }
`

export const customerOrders = async (
  input: number
): Promise<CustomerOrdersQuery | undefined> => {
  const variables: CustomerOrdersQueryVariables = {
    customerID: input,
  }
  const data = await fetchGQL<
    CustomerOrdersQuery,
    CustomerOrdersQueryVariables
  >(CUSTOMER_SEARCH, variables)
  return data
}

export const customerRelatedOrders = async (
  oid: number
): Promise<CustomerRelatedOrdersQuery | undefined> => {
  const variables: CustomerRelatedOrdersQueryVariables = {
    oid,
  }
  const data = await fetchGQL<
    CustomerRelatedOrdersQuery,
    CustomerRelatedOrdersQueryVariables
  >(CUSTOMER_RELATED_ORDERS, variables)
  return data
}
