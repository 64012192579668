import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import {
  OrderCancelAction,
  OrderDetailsMore,
  OrderItem,
  OrderItemCancelInput,
  OrderItemsCancelInput,
} from "generated/graphql"
import { useMemo, useState } from "react"
import {
  DataGridPro,
  GridColDef,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { enqueueSnackbar } from "notistack"

const cancelItemsColumns: GridColDef<OrderItem>[] = [
  {
    field: "artnum",
    headerName: "BDB",
    width: 80,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={{
            cursor: "pointer",
            textDecoration: "&&erline",
            color: "primary.main",
          }}
          component="div"
          onClick={() =>
            window.open(
              `${window.location.origin}/#/editArticle/${params.row.artnum}`,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          {params.row.artnum}
        </Typography>
      )
    },
  },
  {
    field: "quantity",
    headerName: "Storno-menge",
    width: 120,
    type: "number",
    editable: true,
  },
  {
    field: "botname",
    headerName: "Botname",
    width: 280,
  },
  {
    field: "size",
    headerName: "Größe",
    renderCell: (params) => {
      let vonBis = params.row.von + " - " + params.row.bis
      if (params.row.von === params.row.bis) vonBis = params.row.von + ""
      if (params.row.von === 0 && params.row.bis === 0) vonBis = ""

      let stuVonBis = params.row.stuvon + " - " + params.row.stubis
      if (params.row.stuvon === params.row.stubis)
        stuVonBis = params.row.stuvon + ""
      if (params.row.stuvon === 0 && params.row.stubis === 0) stuVonBis = ""
      return (
        <Box>
          <Typography variant="body2" component="div">
            {vonBis}
          </Typography>

          {params.row.stahoeh > 0 && (
            <Typography variant="body2" color="text.secondary" component="div">
              Stahoeh: {params.row.stahoeh}
            </Typography>
          )}

          {stuVonBis !== "" && (
            <Typography variant="body2" color="text.secondary" component="div">
              Stu: {stuVonBis}
            </Typography>
          )}
        </Box>
      )
    },
    width: 140,
  },
  {
    field: "anzahl",
    headerName: "Anzahl",
    width: 80,
  },
]

const CustomToolbar = (
  props: NonNullable<GridSlotsComponentsProps["toolbar"]>
) => {
  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )
}

type CancelOrderDialogProps = {
  open: boolean
  close: () => void
  handleCancel: (arg: OrderItemsCancelInput) => void
  orderItems: OrderItem[]
  order: OrderDetailsMore
}

const emptyCancelOrderInput: OrderItemsCancelInput = {
  orderID: "",
  orderItems: [],
  action: OrderCancelAction.CreditBackToCustomerDeposit,
}

enum CancelOrderStep {
  Start,
  SelectItems,
  Confirm,
}

const stepDialogConentStyle = {}
type OrderItemsMore = OrderItem & { quantity: number | undefined }

export const CancelOrderDialog = (props: CancelOrderDialogProps) => {
  const {
    open: isStepDialogOpen,
    close,
    handleCancel,
    orderItems,
    order,
  } = { ...props }
  const ref = useGridApiRef()
  const validNotSplittedItems = useMemo(() => {
    return orderItems.filter((item) => !item.splitstatus)
  }, [orderItems])
  const [cancelOrderInput, setCancelOrderInput] =
    useState<OrderItemsCancelInput>(emptyCancelOrderInput)
  const [isReallyConfirmOpen, setIsReallyConfirmOpen] = useState<boolean>(false)
  const [step, setStep] = useState<CancelOrderStep>(CancelOrderStep.Start)

  const handleNotCancel = () => {
    close()
    setIsReallyConfirmOpen(false)
    setStep(CancelOrderStep.Start)
    setCancelOrderInput(emptyCancelOrderInput)
  }

  const handleReallyCancel = () => {
    close()
    setIsReallyConfirmOpen(false)
    handleCancel(cancelOrderInput)
    setStep(CancelOrderStep.Start)
    setCancelOrderInput(emptyCancelOrderInput)
  }

  const backToStart = () => {
    setStep(CancelOrderStep.Start)
    setCancelOrderInput(emptyCancelOrderInput)
  }

  const handleConfirmSelectItems = () => {
    const rows: Map<number, OrderItemsMore> =
      ref.current?.getSelectedRows() as Map<number, OrderItemsMore>
    const items = Array.from(rows.values())
    if (items.length === 0) {
      enqueueSnackbar("Bitte wählen Sie mindestens einen Artikel aus", {
        variant: "info",
      })
      return
    }
    const newCancelOrderItems: OrderItemCancelInput[] = []
    for (let i = 0; i < items.length; i++) {
      let quantity: number = 0
      if (items[i].quantity !== undefined && items[i].quantity !== null) {
        quantity = items[i].quantity as number
      }
      if (
        items[i].quantity === undefined ||
        items[i].quantity === null ||
        quantity <= 0
      ) {
        enqueueSnackbar(
          `Bitte geben Sie eine gültige Menge ein BDB: ${items[i].artnum} Botname: ${items[i].botname}`,
          { variant: "info" }
        )
        return
      }
      if (quantity > items[i].anzahl) {
        enqueueSnackbar(
          `Stornomenge ist größer als die bestellte Menge BDB: ${items[i].artnum} Botname: ${items[i].botname}`,
          { variant: "info" }
        )
        return
      }
      newCancelOrderItems.push({
        articleId: items[i].artnum.toString(),
        count: quantity,
      })
    }
    setCancelOrderInput({
      orderID: order.orderID.toString(),
      orderItems: newCancelOrderItems,
      action: OrderCancelAction.CreditBackToCustomerDeposit,
    })
    setStep(CancelOrderStep.Confirm)
  }

  return (
    <>
      <Dialog
        open={isStepDialogOpen}
        onClose={handleNotCancel}
        fullWidth
        maxWidth="md"
      >
        {step === CancelOrderStep.Start && (
          <DialogTitle>Auftrag stornieren</DialogTitle>
        )}
        {step === CancelOrderStep.Start && (
          <DialogContent sx={stepDialogConentStyle}>
            <Typography>
              Ganzen Auftrag oder einzelnen Artikel stornieren?
            </Typography>
          </DialogContent>
        )}
        {step === CancelOrderStep.Start && (
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setStep(CancelOrderStep.SelectItems)}
            >
              Einzelne Artikel
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setStep(CancelOrderStep.Confirm)}
            >
              Ganzen Auftrag
            </Button>
          </DialogActions>
        )}
        {step === CancelOrderStep.SelectItems && (
          <DialogTitle>Welche Artikel sollen storniert werden?</DialogTitle>
        )}
        {step === CancelOrderStep.SelectItems && (
          <DialogContent sx={stepDialogConentStyle}>
            <Box sx={{ height: 600 }}>
              <DataGridPro
                getRowId={(rows) => rows.ewID}
                rows={validNotSplittedItems}
                apiRef={ref}
                getRowHeight={() => "auto"}
                columns={cancelItemsColumns}
                slots={{
                  toolbar: CustomToolbar,
                }}
                checkboxSelection
                disableRowSelectionOnClick
              />
            </Box>
          </DialogContent>
        )}
        {step === CancelOrderStep.SelectItems && (
          <DialogActions>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={backToStart}
            >
              Zurück
            </Button>

            <Button
              fullWidth
              variant="contained"
              onClick={handleConfirmSelectItems}
            >
              OK
            </Button>
          </DialogActions>
        )}
        {step === CancelOrderStep.Confirm && (
          <DialogTitle>
            {order.bezahlt === 0.0
              ? "Bestellung wurde noch nicht bezahlt."
              : "Was soll mit dem bereits gezahlten Betrag geschehen?"}
          </DialogTitle>
        )}
        {step === CancelOrderStep.Confirm && (
          <DialogContent sx={stepDialogConentStyle}>
            {order.bezahlt !== 0.0 && (
              <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel id="action">Aktion</InputLabel>
                <Select
                  labelId="action"
                  id="action"
                  value={cancelOrderInput.action}
                  label="Aktion"
                  onChange={(e) => {
                    setCancelOrderInput({
                      ...cancelOrderInput,
                      action:
                        e.target.value ===
                        OrderCancelAction.CreditBackToCustomerDeposit
                          ? OrderCancelAction.CreditBackToCustomerDeposit
                          : OrderCancelAction.TransferToBankAccount,
                    })
                  }}
                >
                  <MenuItem
                    value={OrderCancelAction.CreditBackToCustomerDeposit}
                  >
                    Kundenkonto
                  </MenuItem>
                  <MenuItem value={OrderCancelAction.TransferToBankAccount}>
                    Rücküberweisung
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </DialogContent>
        )}
        {step === CancelOrderStep.Confirm && (
          <DialogActions>
            <Button
              color="error"
              fullWidth
              variant="contained"
              onClick={handleNotCancel}
            >
              Abbrechen
            </Button>

            <Button
              fullWidth
              variant="contained"
              onClick={() => setIsReallyConfirmOpen(true)}
            >
              Stornieren
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={isReallyConfirmOpen}
        onClose={() => setIsReallyConfirmOpen(false)}
      >
        <DialogTitle>Wirklich stornieren ?</DialogTitle>
        <DialogActions>
          <Button
            fullWidth
            color="error"
            variant="contained"
            onClick={handleNotCancel}
          >
            Nein
          </Button>
          <Button fullWidth variant="contained" onClick={handleReallyCancel}>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
