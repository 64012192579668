import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { OrderStatusAutocomplete } from "components/OrderStatusAutocomplete/OrderStatusAutocomplete"

type DialogSplitOrderProps = {
  open: boolean
  close: () => void
  splitOrder: () => void
  articles: number[]
  splitStatus: number
  setSplitStatus: (arg: number) => void
}

export const DialogSplitOrder = (props: DialogSplitOrderProps) => {
  const { open, close, splitOrder, articles, splitStatus, setSplitStatus } = {
    ...props,
  }

  return (
    <Dialog fullWidth open={open} onClose={close}>
      <DialogTitle>Auftrag wirklich splitten?</DialogTitle>
      <DialogContent>
        <Typography>Folgende Artikel werden gesplittet:</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {articles.map((item, index) => (
            <Typography key={item} sx={{ mr: 0.5 }}>
              {index < articles.length - 1 ? item + "," : item}
            </Typography>
          ))}
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography sx={{ mb: 1 }}>Bitte einen Status auswählen:</Typography>
          <OrderStatusAutocomplete
            status={splitStatus}
            handleOrderStatusChange={setSplitStatus}
            isSplitDialog
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="error" variant="contained" onClick={close}>
          Nein
        </Button>
        <Button fullWidth variant="contained" onClick={splitOrder}>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  )
}
