import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid-pro"
import { useAppDispatch, useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import { qSnack } from "app/snackSlice"
import Tile from "components/Tile/Tile"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { SupplierOrderedItems } from "generated/graphql"
import { uniqBy } from "lodash"
import { fetchSupplierOrderedItems } from "queries/fetchSupplierOrderedItems"
import { useEffect, useState } from "react"
import { germanDateFormatter } from "utils/datefmt"

const getTreeDataPath: DataGridProProps<
  SupplierOrderedItems & ExtraCols
>["getTreeDataPath"] = (row) => row.hierarchy

type ExtraCols = {
  done: string
  hierarchy: string[]
}

const getDonePercentage = (row: SupplierOrderedItems): string => {
  if (!row.Quantity) return "0"

  return (((row.IsTagged ? row.Quantity : 0) / row.Quantity) * 100).toFixed(2)
}

const columns: GridColDef<SupplierOrderedItems & ExtraCols>[] = [
  {
    field: "done",
    headerName: "Erledigt %",
    width: 150,
  },
  {
    field: "Ewid",
    headerName: "Einkaufswagennr.",
    width: 175,
    valueGetter: (params) => {
      if (params.row.Ewid === 0) return ""
      return params.row.Ewid
    },
  },
  {
    field: "BOTNAME",
    headerName: "Ware",
    width: 300,
    renderCell: (params) => {
      return (
        <Box>
          <Typography variant="body1" component="div">
            {params.row.BOTNAME}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {params.row.Sorte}
          </Typography>
        </Box>
      )
    },
  },
  {
    field: "Orderid",
    headerName: "Bestellnummer",
    width: 200,
    valueGetter: (params) => {
      if (params.row.Orderid === 0) return ""
      return params.row.Orderid
    },
  },
  {
    field: "VonBis",
    valueGetter: (params) => {
      if (!params.row.Von) return ""
      if (!params.row.Bis) return ""
      return params.row.Von.toString() + " - " + params.row.Bis.toString()
    },
    headerName: "Größe",
    width: 100,
  },
  {
    field: "Quantity",
    headerName: "Erhalten/Bestellt",
    width: 200,
    valueGetter: (params) => {
      if (!params.row.Quantity) return ""
      return (
        (params.row.IsTagged ? params.row.Quantity.toString() : "0") +
        "/" +
        params.row.Quantity.toString()
      )
    },
  },
  {
    valueGetter: (params) => {
      if (!params.value) return ""
      return germanDateFormatter.format(new Date(params.row.CreatedAt))
    },
    field: "CreatedAt",
    headerName: "Bestelldatum",
    width: 250,
  },
]

const sortOrderItems = (
  data: SupplierOrderedItems[]
): (SupplierOrderedItems & ExtraCols)[] => {
  const result: (SupplierOrderedItems & ExtraCols)[] = []
  for (let i = 0; i < data.length; i++) {
    const done = getDonePercentage(data[i])
    const hierarchy = [data[i].Lieferant, data[i].RoundListID.toString()]
    result.push({ ...data[i], done, hierarchy })
  }
  // GET UNIQUE SUPPLIER NAMES
  //console.log(result)
  const uniqueSupplierNames = uniqBy(result, "Lieferant")
  // now create an item for each supplier with the sum of the items in % done
  for (let i = 0; i < uniqueSupplierNames.length; i++) {
    let totalItemsOrdered = 0
    let totalItemsTagged = 0
    for (let j = 0; j < result.length; j++) {
      if (result[j].Lieferant === uniqueSupplierNames[i].Lieferant) {
        totalItemsOrdered += result[j].Quantity
        totalItemsTagged += result[j].IsTagged ? result[j].Quantity : 0
      }
    }
    const done = ((totalItemsTagged / totalItemsOrdered) * 100).toFixed(2)
    result.push({
      hierarchy: [uniqueSupplierNames[i].Lieferant],
      done: done,
      BOTNAME: "",
      Bis: NaN,
      CreatedAt: "",
      Ewid: 0,
      Flavour: 0,
      G24: 0,
      GERNAME: "",
      Is24hDelivery: false,
      IsTagged: false,
      Lieferant: "",
      MainbaseBdb: 0,
      Orderid: 0,
      Quantity: 0,
      RoundListID: uniqueSupplierNames[i].RoundListID + 10000000,
      Sorte: "",
      SupplierID: 0,
      Von: 0,
    })
  }

  return result
}

const SupplierOrderedItemsPage = () => {
  const dispatch = useAppDispatch()
  const round = useAppSelector(selectCurrentRound)
  const [data, setData] = useState<(SupplierOrderedItems & ExtraCols)[]>([])
  const [showAll, setShowAll] = useState(false)
  const [showTagged, setShowTagged] = useState(false)
  const [showWarn, setShowWarn] = useState(true)
  const [showError, setShowError] = useState(true)

  //load the active rounds and focus on the round text input
  useEffect(() => {
    if (!round) return
    if (round.round < 1) return
    //fetch data from server
    fetchSupplierOrderedItems(round.round).then((data) => {
      let supplierOrderedItems: SupplierOrderedItems[] = []
      if (data?.supplierOrderedItems) {
        dispatch(
          qSnack({
            msg: "Lieferantenbestellung erfolgreich geladen",
            severity: "success",
          })
        )
        supplierOrderedItems = data.supplierOrderedItems
      }
      const ll = sortOrderItems(supplierOrderedItems)
      setData(ll)
    })
  }, [round, dispatch])

  const reloadData = () => {
    if (!round) return
    if (round.round < 1) return
    //fetch data from server
    fetchSupplierOrderedItems(round.round).then((data) => {
      let supplierOrderedItems: SupplierOrderedItems[] = []
      if (data?.supplierOrderedItems) {
        dispatch(
          qSnack({
            msg: "Lieferantenbestellung erfolgreich geladen",
            severity: "success",
          })
        )
        supplierOrderedItems = data.supplierOrderedItems
      }
      const ll = sortOrderItems(supplierOrderedItems)
      setData(ll)
    })
  }

  // set the css style for the row when the order is not delivered
  // If a plant from the order is delivered by a G24 tree school (extra flag in the database), it should be at Pflanzmich the day after the order has been placed.
  // E.g. the customer orders the plants on the 6.4., the plant should be at least etikettiert by the 7.4. If this is not the case, a yellow signal appears.
  // If the order is due one day, so the plant on the 8.4. is still not etikettiert, then a red signal appears.

  // If a plant is delivered by a Pinneberger tree school (field flavor in the db = 0), it should be at Pflanzmich latest two days after the order has been placed.
  // E.g. the customer orders the plants on the 6.4., the plant should be at least etikettiert by the 8.4. If this is not the case, a yellow signal appears.
  // If the order is due, so the plant on the 9.4. is still not etikettiert, then a red signal appears.

  // If a plant is delivered by a Oldenburger tree school (field flavor in the db = 2), it should be at Pflanzmich latest on Wednesday next week after the order has been placed.
  // E.g. the customer orders the plants on the 6.4., the plant should be at least etikettiert by Wednesday of the following week. If this is not the case, a yellow signal appears.
  // If the order is due, so the plant is on the Thursday of the following week still not etikettiert, then a red signal appears.
  const getClassNameForRow = (row: SupplierOrderedItems): string => {
    const currentTime = dayjs()
    const createdAt = dayjs(row.CreatedAt).hour(0).minute(0).second(0)
    let minWarningDate = createdAt.add(1, "day")
    let minErrorDate = minWarningDate.add(1, "day")

    if (row.Flavour === 0) {
      minWarningDate = createdAt.add(2, "day")
      minErrorDate = minWarningDate.add(1, "day")
    } else if (row.Flavour === 2) {
      if (createdAt.day() === 0) {
        minWarningDate = createdAt.add(2, "day")
      } else {
        minWarningDate = createdAt.add(9 - createdAt.day(), "day")
      }
      minErrorDate = minWarningDate.add(1, "day")
    }

    if (row.IsTagged) {
      return "success-tagged"
    }
    if (currentTime.isAfter(minErrorDate)) {
      return "error-tagged"
    } else if (currentTime.isAfter(minWarningDate)) {
      return "warning-tagged"
    }
    // just orderd item no color highlight
    return ""
  }

  const filterData = (
    data: (SupplierOrderedItems & ExtraCols)[]
  ): (SupplierOrderedItems & ExtraCols)[] => {
    if (showAll) return data
    const filtered: (SupplierOrderedItems & ExtraCols)[] = []
    for (let i = 0; i < data.length; i++) {
      const className = getClassNameForRow(data[i])
      if (data[i].IsTagged && showTagged) {
        filtered.push(data[i])
        continue
      } else if (className === "warning-tagged" && showWarn) {
        filtered.push(data[i])
        continue
      } else if (className === "error-tagged" && showError) {
        filtered.push(data[i])
        continue
      }
    }
    return filtered
  }

  const getTotalQuantity = (data: SupplierOrderedItems[]): number => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      total += data[i].Quantity
    }
    return total
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Button variant="contained" onClick={reloadData}>
            Aktualisieren
          </Button>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAll}
                onChange={(e) => {
                  setShowAll(e.target.checked)
                }}
              />
            }
            label="Alle anzeigen"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showTagged}
                onChange={(e) => {
                  setShowTagged(e.target.checked)
                }}
              />
            }
            label="Etikettiert anzeigen"
          />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showWarn}
                onChange={(e) => {
                  setShowWarn(e.target.checked)
                }}
                color="warning"
              />
            }
            label="Warnung anzeigen"
          />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showError}
                onChange={(e) => {
                  setShowError(e.target.checked)
                }}
                color="error"
              />
            }
            label="Verspätet anzeigen"
          />
        </Grid>

        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "space-between",
            }}
          >
            <Tile label="Artikel" value={getTotalQuantity(data).toString()} />
            <Tile label="Posten" value={data.length.toString()} noMarginRight />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ height: "80vh", width: "100%" }}>
            <DataGridPro
              treeData
              rows={filterData(data)}
              getRowClassName={(params) => {
                return getClassNameForRow(params.row)
              }}
              getTreeDataPath={getTreeDataPath}
              rowHeight={80}
              getRowId={(row) => row.RoundListID}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              columns={columns}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
              }}
              groupingColDef={{
                headerName: "Lieferant",
                sortable: true,
                sortComparator: (v1, v2) => {
                  const name1 = v1 ? v1.toString() : ""
                  const name2 = v2 ? v2.toString() : ""
                  return name1.localeCompare(name2)
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SupplierOrderedItemsPage
