import { fetchGQL } from "app/api"
import {
  AddCustomerDebitMutation,
  AddCustomerDebitMutationVariables,
  CustomerDebitQuery,
  CustomerDebitQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const CUSTOMER_DEBIT = gql`
  query customerDebit($in: CustomerDebitRequest!) {
    customerDebit(in: $in) {
      id
      customerId
      orderId
      value
      datum
      last_admin
      title
      note
    }
  }
`

const ADD_CUSTOMER_DEBIT = gql`
  mutation addCustomerDebit($in: CustomerDebitInput!) {
    addCustomerDebit(in: $in)
  }
`

export const fetchCustomerDebit = async (
  customerId: number
): Promise<CustomerDebitQuery | undefined> => {
  const variables: CustomerDebitQueryVariables = { in: { customerId } }
  const data = await fetchGQL<CustomerDebitQuery, CustomerDebitQueryVariables>(
    CUSTOMER_DEBIT,
    variables
  )
  return data
}

export const fetchAddCustomerDebit = async (
  customerId: number,
  orderId: number,
  value: number,
  title: string,
  note: string
): Promise<AddCustomerDebitMutation | undefined> => {
  const variables: AddCustomerDebitMutationVariables = {
    in: {
      customerId,
      orderId,
      value,
      title,
      note,
    },
  }
  const data = await fetchGQL<
    AddCustomerDebitMutation,
    AddCustomerDebitMutationVariables
  >(ADD_CUSTOMER_DEBIT, variables)
  return data
}
