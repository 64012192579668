import { fetchGQL } from "app/api"
import {
  GetSplitMergeStatusesQuery,
} from "generated/graphql"
import { gql } from "graphql-tag"

const SPLITABLE_MERGEABLE_STATUSLIST = gql`
query getSplitMergeStatuses {
  getSplitMergeStatuses {
    status
    remark
    splitable
    mergeable
    etiStop
    verteilenStop
    packStop
  }
}
`

export const fetchSplitableMergeable = async (): Promise<GetSplitMergeStatusesQuery| undefined> => {
  const data = await fetchGQL<
    GetSplitMergeStatusesQuery,
   undefined 
  >(SPLITABLE_MERGEABLE_STATUSLIST, undefined)
  return data
}
