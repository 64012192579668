import { fetchGQL } from "app/api"
import {
  Maybe,
  RoundCreateInput,
  SortOrdersMutation,
  SortOrdersMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const SORT_ORDER = gql`
  mutation sortOrders($input: SortOrderInput!) {
    sortOrder(in: $input) {
      message
      preview {
        bdb
        quantity
        botname
        supplier
        singles
        soldStatus
      }
    }
  }
`

export const fetchSortOrder = async (
  oldStatus: string,
  newStatus: string,
  preview: boolean,
  onlySingle: boolean,
  createNewRound: boolean,
  splitSoldOut: boolean,
  plantTypeIds: number[],
  supplierIds: number[],
  onlyEmptyNotiz: boolean,
  paymentMethod: string,
  fromDate: string,
  toDate: string,
  roundDetails: Maybe<RoundCreateInput>
): Promise<SortOrdersMutation | undefined> => {
  const variables: SortOrdersMutationVariables = {
    input: {
      oldStatus: oldStatus,
      newStatus: newStatus,
      preview: preview,
      onlySingles: onlySingle,
      plantTypeIDs: plantTypeIds,
      onlyEmptyNotiz: onlyEmptyNotiz,
      paymentMethod: paymentMethod,
      supplierIDs: supplierIds,
      splitSoldOut: splitSoldOut,
      fromDate: fromDate,
      toDate: toDate,
      createNewRound: createNewRound,
      roundDetails: roundDetails,
    },
  }
  const data = await fetchGQL<SortOrdersMutation, SortOrdersMutationVariables>(
    SORT_ORDER,
    variables
  )
  return data
}
