import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import {
  DataGridPro,
  GridColDef,
  GridSlotsComponentsProps,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro"
import { CollapsibleTable } from "components/CollapsibleTable/CollapsibleTable"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { CustomerDebit } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import {
  fetchAddCustomerDebit,
  fetchCustomerDebit,
} from "queries/fetchCustomerDebit"
import { useMemo, useState } from "react"

export interface CustomerDebitProps {
  customerID: string
}

const columnsDebit: GridColDef<CustomerDebit>[] = [
  {
    type: "date",
    field: "datum",
    headerName: "Datum",
    width: 100,
    valueGetter: (params) => {
      if (params.value === null || params.value === undefined) {
        return dayjs("1970-01-01")
      }
      return dayjs(params.value)
    },
    valueFormatter: (params) => {
      return dayjs(params.value).format("DD.MM.YYYY")
    },
  },
  {
    type: "number",
    field: "value",
    headerName: "Betrag",
    width: 100,
  },
  {
    field: "title",
    headerName: "Titel",
    width: 200,
    type: "string",
  },
  {
    field: "note",
    headerName: "Notiz",
    width: 200,
    type: "string",
  },
  {
    field: "last_admin",
    headerName: "Letzter Bearbeiter",
    width: 200,
    type: "string",
  },
  {
    field: "orderId",
    headerName: "Order ID",
    width: 200,
  },
]

const CustomerDebitPage = (props: CustomerDebitProps) => {
  const { customerID } = props
  const [rows, setRows] = useState<CustomerDebit[]>([])
  const [addDebitIsOpen, setAddDebitIsOpen] = useState(false)
  const [addDebitValue, setAddDebitValue] = useState(0)
  const [debitTitle, setDebitTitle] = useState("")
  const [debitNote, setDebitNote] = useState("")
  const [debitOrderId, setDebitOrderId] = useState("")

  const handleLoadDebit = (open: boolean) => {
    if (!open) {
      return
    }
    if (customerID === "") {
      return
    }
    fetchCustomerDebit(parseInt(customerID)).then((data) => {
      if (data) {
        setRows(data.customerDebit)
      } else {
        enqueueSnackbar("Fehler beim Laden der Debitdaten", {
          variant: "error",
        })
      }
    })
  }

  const totalSum = useMemo(() => {
    // Calculate total
    return rows.reduce((acc, row) => {
      return acc + row.value
    }, 0)
  }, [rows])

  const CustomFooterStatusComponent = (
    _: NonNullable<GridSlotsComponentsProps["footer"]>
  ) => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Typography variant="h6">Gesamt: {totalSum.toFixed(2)} </Typography>
      </GridToolbarContainer>
    )
  }

  const handleAddDebit = () => {
    if (customerID === "") {
      enqueueSnackbar("Kunden ID fehlt", { variant: "error" })
      return
    }
    if (addDebitValue === 0) {
      enqueueSnackbar("Betrag darf nicht 0.00 sein", { variant: "info" })
      return
    }
    const cid = parseInt(customerID)
    if (isNaN(cid)) {
      enqueueSnackbar("Kunden ID ist keine Zahl", { variant: "error" })
      return
    }

    // add debit
    fetchAddCustomerDebit(
      cid,
      parseInt(debitOrderId),
      addDebitValue,
      debitTitle,
      debitNote
    )
      .then((data) => {
        if (data?.addCustomerDebit) {
          enqueueSnackbar("Guthaben erfolgreich hinterlegt", {
            variant: "success",
          })
        }
        fetchCustomerDebit(cid).then((data) => {
          if (data) {
            setRows(data.customerDebit)
          } else {
            enqueueSnackbar("Fehler beim Laden der Debitdaten", {
              variant: "error",
            })
          }
        })
      })
      .finally(() => {
        setAddDebitValue(0)
        setDebitTitle("")
        setDebitNote("")
        setDebitOrderId("")
        setAddDebitIsOpen(false)
      })
  }

  return (
    <CollapsibleTable
      defaultOpen={false}
      title="Kundenkonto"
      onOpenChange={handleLoadDebit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddDebitIsOpen(true)}
          >
            Guthaben hinterlegen
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 600 }}>
            <DataGridPro
              rows={rows}
              columns={columnsDebit}
              localeText={dataGridDEde}
              slots={{
                footer: CustomFooterStatusComponent,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Dialog open={addDebitIsOpen}>
            <DialogTitle>Guthaben hinterlegen</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Hier können Sie ein Guthaben für den Kunden hinterlegen
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Betrag"
                    type="number"
                    fullWidth
                    value={addDebitValue}
                    onChange={(e) =>
                      setAddDebitValue(parseFloat(e.target.value))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Titel"
                    fullWidth
                    value={debitTitle}
                    onChange={(e) => setDebitTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Notiz"
                    fullWidth
                    value={debitNote}
                    onChange={(e) => setDebitNote(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Order ID"
                    value={debitOrderId}
                    onChange={(e) => setDebitOrderId(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setAddDebitIsOpen(false)}
                color="error"
                variant="contained"
                fullWidth
              >
                Abbrechen
              </Button>
              <Button
                onClick={handleAddDebit}
                color="primary"
                fullWidth
                variant="contained"
              >
                Speichern
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </CollapsibleTable>
  )
}

export default CustomerDebitPage
