import { Assessment } from "@mui/icons-material"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import CachedIcon from "@mui/icons-material/Cached"
import GridViewIcon from "@mui/icons-material/GridView"
import Label from "@mui/icons-material/Label"
import MoveDownIcon from "@mui/icons-material/MoveDown"
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import SpeedIcon from "@mui/icons-material/Speed"
import StorageIcon from "@mui/icons-material/Storage"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import { SvgIconProps } from "@mui/material/SvgIcon"
import {
  ADMIN,
  CUSTOMER_SUPPORT,
  ROUNDS,
  SHIPPING_CREATE,
  SHIPPING_STATISTIC,
  STATISTIC,
} from "constants/privileges"

export interface NavItem {
  id: string
  label: string
  Icon: React.ElementType<SvgIconProps>
  userPrivilege: number
  navTo?: string
  labelInfo?: string
  children?: NavItem[]
}

export const ALL = 0 // visible for all users

export const navigationPages: NavItem[] = [
  {
    id: "Benutzerverwalten",
    label: "Benutzer verwalten",
    Icon: SupervisorAccountIcon,
    userPrivilege: ADMIN,
    navTo: "/userAdministration",
  },
  {
    id: "1",
    label: "Rundenstatistik heute",
    Icon: CachedIcon,
    userPrivilege: STATISTIC,
    navTo: "/",
  },
  {
    id: "2",
    label: "Leistung",
    Icon: SpeedIcon,
    userPrivilege: STATISTIC,
    navTo: "/leistung",
  },
  {
    id: "Verkaufsstatistik",
    label: "Verkaufsstatistik",
    Icon: QueryStatsIcon,
    userPrivilege: STATISTIC,
    navTo: "/statistic",
  },
  {
    id: "Mitarbeiterstatistik",
    label: "Mitarbeiterstatistik",
    Icon: Assessment,
    userPrivilege: STATISTIC,
    navTo: "/employeeStats",
  },
  {
    id: "rounds",
    label: "Runden",
    Icon: CachedIcon,
    userPrivilege: ROUNDS,
    children: [
      {
        id: "roundOverview",
        label: "Übersicht",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/roundOverview",
      },
      {
        id: "sortOrders",
        label: "Sortieren",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/sortOrders",
      },
      {
        id: "roundCreate",
        label: "Erstellen",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/roundCreate",
      },
      {
        id: "roundItemsEdit",
        label: "Bearbeiten",
        Icon: Label,
        navTo: "/roundOrders",
        userPrivilege: ROUNDS,
      },
      {
        id: "sendBestellfaxe",
        label: "Versenden",
        Icon: Label,
        navTo: "/orderList",
        userPrivilege: ROUNDS,
      },
      {
        id: "printSupplierOrders",
        label: "Bestellung drucken",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/printSupplierOrders",
      },
      {
        id: "printCustomerOrders",
        label: "Kistenzettel drucken",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/printCustomerOrders",
      },
      {
        id: "printLabels",
        label: "Etiketten drucken",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/printLabels",
      },
      {
        id: "trackItems",
        label: "Nachverfolgen",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/roundTrack",
      },
      {
        id: "driverAppAdminPanel",
        label: "Abholungen",
        Icon: Label,
        userPrivilege: ROUNDS,
        navTo: "/driverAppAdminPanel",
      },
    ],
  },
  {
    id: "Stammdaten",
    label: "Stammdaten",
    Icon: StorageIcon,
    userPrivilege: ALL,
    children: [
      {
        id: "searchArticle",
        label: "Artikel",
        Icon: Label,
        userPrivilege: ALL,
        navTo: "/searchArticle",
      },
      {
        id: "supplierData",
        label: "Lieferanten",
        Icon: Label,
        userPrivilege: ALL,
        navTo: "/lieferanten",
      },
    ],
  },
  {
    id: "4",
    label: "Versand",
    Icon: MoveDownIcon,
    userPrivilege: SHIPPING_STATISTIC,
    children: [
      {
        id: "5",
        label: "Versandübersicht",
        Icon: Label,
        userPrivilege: SHIPPING_STATISTIC,
        navTo: "/packagestats",
      },
      {
        id: "6",
        label: "Fehler an den Packstationen",
        Icon: Label,
        userPrivilege: SHIPPING_STATISTIC,
        navTo: "/versandfehler",
      },
      {
        id: "13",
        label: "RS Logistik",
        Icon: Label,
        userPrivilege: SHIPPING_CREATE,
        navTo: "/rs",
      },
      {
        id: "14",
        label: "Raben",
        Icon: Label,
        userPrivilege: SHIPPING_CREATE,
        navTo: "/raben",
      },
      {
        id: "RmLogistik",
        label: "RML",
        Icon: Label,
        userPrivilege: SHIPPING_CREATE,
        navTo: "/rml",
      },
    ],
  },
  {
    id: "7",
    label: "Kartonverwaltung",
    Icon: GridViewIcon,
    userPrivilege: SHIPPING_STATISTIC,
    children: [
      {
        id: "8",
        label: "Statistik",
        Icon: Label,
        userPrivilege: SHIPPING_STATISTIC,
        navTo: "/cartonInfo",
      },
      {
        id: "9",
        label: "Editieren",
        Icon: Label,
        userPrivilege: SHIPPING_STATISTIC,
        navTo: "/cartonEdit",
      },
    ],
  },
  {
    id: "serviceWorker",
    label: "Kundenservice",
    Icon: SupportAgentIcon,
    navTo: "/searchCustomer",
    userPrivilege: CUSTOMER_SUPPORT,
    children: [
      {
        id: "searchCustomer",
        label: "Kundensuche",
        Icon: Label,
        navTo: "/searchCustomer",
        userPrivilege: CUSTOMER_SUPPORT,
      },
      {
        id: "searchOrder",
        label: "Auftragssuche",
        Icon: Label,
        navTo: "/searchOrder",
        userPrivilege: CUSTOMER_SUPPORT,
      },
      {
        id: "coupon",
        label: "Gutscheinverwaltung",
        Icon: Label,
        navTo: "/couponlist",
        userPrivilege: CUSTOMER_SUPPORT,
      },
    ],
  },
  {
    id: "refundList",
    label: "Rücküberweisungen",
    Icon: AttachMoneyIcon,
    navTo: "/refundList",
    userPrivilege: CUSTOMER_SUPPORT,
  },
]
