import { Container, Grid } from "@mui/material"

import { useParams } from "react-router-dom"
import { CustomerEditPageAddress } from "./CustomerEditPageAddress"
import { CustomerEditPageBaseData } from "./CustomerEditPageBaseData"
import { CustomerEditPageOrders } from "./CustomerEditPageOrders"
import CustomerDebit from "pages/CustomerDebit/CustomerDebit"

const CustomerEditPage = (): JSX.Element => {
  const { customerID } = useParams()

  return (
    <>
      {customerID !== undefined && (
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomerEditPageBaseData customerID={customerID} />
            </Grid>
            <Grid item xs={12}>
              <CustomerEditPageOrders customerID={customerID} addOrder={true} />
            </Grid>
            <Grid item xs={12}>
              <CustomerEditPageAddress customerID={customerID} open={false} />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: 2 }}>
              <CustomerDebit customerID={customerID} />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default CustomerEditPage
