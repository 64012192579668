import { fetchGQL } from "app/api"
import {
  SupplierRoundInfoQuery,
  SupplierRoundInfoQueryVariables,
  SupplierRoundInfoSaveMutation,
  SupplierRoundInfoSaveMutationVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

const SUPPLIER_ROUND_NOTE = gql`
  query supplierRoundInfo($round: Int!) {
    supplierRoundInfos(round: $round) {
      supplierId
      note
      requiresCollection
      collectionDate
      collectionTime
    }
  }
`

export const supplierRoundInfoGet = async (
  round: number
): Promise<SupplierRoundInfoQuery | undefined> => {
  const variables: SupplierRoundInfoQueryVariables = { round }
  const data = await fetchGQL<
    SupplierRoundInfoQuery,
    SupplierRoundInfoQueryVariables
  >(SUPPLIER_ROUND_NOTE, variables)
  return data
}

const SUPPLIER_ROUND_NOTE_SAVE = gql`
  mutation supplierRoundInfoSave($in: SupplierRoundInfoSaveInput!) {
    supplierRoundInfoSave(in: $in)
  }
`

export const supplierRoundInfoSave = async (
  variables: SupplierRoundInfoSaveMutationVariables
): Promise<SupplierRoundInfoSaveMutation | undefined> => {
  const data = await fetchGQL<
    SupplierRoundInfoSaveMutation,
    SupplierRoundInfoSaveMutationVariables
  >(SUPPLIER_ROUND_NOTE_SAVE, variables)
  return data
}
