import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-pro"
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro"
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro"
import {
  getCurrentCalendarWeek,
  gridTileCss,
  RoundCreateContent,
  translateEnglishToGermanWeekday,
} from "components/CreateRoundDialog/CreateRoundDialog"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs, { Dayjs } from "dayjs"
import {
  FilterTemplate,
  Maybe,
  PlantOrderSum,
  PlantType,
  RoundCreateInput,
  SortOrderBaseQuery,
  SortOrderInfo,
} from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { TransitionSlideIn } from "pages/ArticleEditPage/ArticleEditPage"
import { getGroupName, groupList } from "pages/OrderList/OrderList"
import { fetchOrderAggregateByStatus } from "queries/fetchOrderWithStatus"
import { fetchSortOrder } from "queries/fetchSortOrder"
import { fetchPlantTypesAndSupplier } from "queries/fetchSortOrderBase"
import {
  deleteFilterTemplate,
  getFilterTemplates,
  saveFilterTemplate,
} from "queries/filterTemplates"
import { getNextRoundNumber } from "queries/getNextRoundNumber"
import { ordersByStatusAndBdb } from "queries/ordersByStatusAndBdb"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { shortcutsItems } from "utils/shortcutItems"

const colsPlantOrderDetails: GridColDef<SortOrderInfo>[] = [
  {
    field: "orderID",
    headerName: "Order ID",
  },
  {
    field: "custId",
    headerName: "Kunden ID",
  },
  {
    field: "ewID",
    headerName: "Einkaufswagennr.",
    width: 200,
  },
  {
    field: "sh",
    headerName: "Kiste (sh)",
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    type: "number",
  },
  {
    field: "isSingle",
    headerName: "Single",
    type: "boolean",
  },
  {
    field: "country",
    headerName: "Land",
  },
  {
    field: "zip",
    headerName: "PLZ",
  },
  {
    field: "notiz",
    headerName: "Notiz",
    width: 500,
    renderCell: (params) => (
      <Typography
        variant="body2"
        component="div"
        sx={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "zielDat",
    headerName: "Zieldatum",
    width: 200,
  },
]

const cols: GridColDef<PlantOrderSum>[] = [
  {
    field: "bdb",
    headerName: "BDB",
    width: 100,
    renderCell: (params) => {
      return (
        <Link href={`/#/editArticle/${params.row.bdb}`}>
          <Typography variant="body2" color="secondary" component="div">
            {params.row.bdb}
          </Typography>
        </Link>
      )
    },
  },
  {
    field: "botname",
    headerName: "Pflanze",
    width: 300,
  },
  {
    field: "quantity",
    headerName: "Anzahl",
    width: 100,
    type: "number",
  },
  {
    field: "supplier",
    headerName: "Lieferant",
    width: 150,
  },
  {
    field: "soldStatus",
    headerName: "Verfügbar",
    width: 75,
  },
]

const paymentMethods = [
  { key: "1", label: "Alle", value: "" },
  { key: "2", label: "Rechnung", value: "Rechnung" },
  { key: "3", label: "Paypal", value: "Paypal" },
  { key: "4", label: "Vorkasse", value: "Vorkasse" },
]

interface Template {
  dateRange: DateRange<Dayjs>
  fetchStatus: string
  targetStatus: string
  plantTypeIds: number[]
  onlySingle: boolean
  splitSoldOut: boolean
  onlyEmptyNotiz: boolean
  createNewRound: boolean
  paymentMethod: string
  selectedGroups: string[]
  selectedSupplierIds: number[]
}

const SortOrderPage: React.FC = () => {
  const [orderDetailsForPlantIsOpen, setOrderDetailsForPlantIsOpen] =
    useState<boolean>(false)
  const [orderPlantDetails, setOrderPlantDetails] = useState<SortOrderInfo[]>(
    []
  )
  const [filterDialogIsOpen, setFilterDialogIsOpen] = useState<boolean>(false)
  const [currentBDB, setCurrentBDB] = useState<string>("")
  const [currentBotname, setCurrentBotname] = useState<string>("")
  const statusRef = useRef<HTMLInputElement>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>("")
  const [onlyEmptyNotiz, setOnlyEmptyNotiz] = useState<boolean>(false)
  const [baseData, setBaseData] = useState<SortOrderBaseQuery | undefined>(
    undefined
  )
  const [newFilterTemplateName, setNewFilterTemplateName] = useState<string>("")
  const [template, setTemplate] = React.useState<string>("1")
  const [filterTemplates, setFilterTemplates] = useState<FilterTemplate[]>([])
  const [targetStatus, setTargetStatus] = useState<string>("")
  const [allSupplierOptions, setAllSupplierOptions] = useState<any[]>([])
  const [allOrders, setAllOrders] = useState<PlantOrderSum[]>([])
  const [previewOrders, setPreviewOrders] = useState<PlantOrderSum[]>([])
  const [fetchStatus, setFetchStatus] = useState<string>("")
  const [loadingAllOrders, setLoadingAllOrders] = useState<boolean>(false)
  const [previewIsLoading, setPreviewIsLoading] = useState<boolean>(false)
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [selectedPlantTypeIds, setSelectedPlantTypeIds] = useState<number[]>([])
  const [splitSoldOut, setSplitSoldOut] = useState<boolean>(true)
  const [onlySingle, setOnlySingle] = useState<boolean>(false)
  const [createNewRound, setCreateNewRound] = useState<boolean>(false)
  const [commitSortOrderIsOpen, setCommitSortOrderIsOpen] =
    useState<boolean>(false)

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])
  const [roundNumber, setRoundNumber] = useState<string | null>(null)
  const [roundName, setRoundName] = useState(
    translateEnglishToGermanWeekday(dayjs().format("dd")) +
      "KW" +
      getCurrentCalendarWeek()
  )
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs())
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const handleCloseCommitSortOrder = () => {
    setCommitSortOrderIsOpen(false)
  }

  useEffect(() => {
    statusRef.current?.focus()
    fetchPlantTypesAndSupplier().then((data) => {
      setBaseData(data)
      if (data !== undefined) {
        const supOpts: any[] = []
        for (let i = 0; i < data.suppliers.length; i++) {
          supOpts.push({
            id: data.suppliers[i].id,
            label: data.suppliers[i].name,
            selected: false,
          })
        }
        setAllSupplierOptions(supOpts)
      }
    })

    getNextRoundNumber().then((r) => {
      if (r !== undefined && r.getNextRoundNumber > 0) {
        setRoundNumber(r.getNextRoundNumber.toString())
      }
    })
    getFilterTemplates().then((data) => {
      if (data !== undefined) {
        setFilterTemplates(data.filterTemplates)
      }
    })
  }, [])

  const selectedSuppliers = useMemo(
    () => allSupplierOptions.filter((v) => v.selected),
    [allSupplierOptions]
  )

  const handleGroupChange = (newGroups: string[]) => {
    setSelectedGroups(newGroups)
    // find supplier with group name in selected groups
    const newSupplierIds: number[] = []
    if (newGroups.includes("Alle")) {
      const allSupplierOptionC = [...allSupplierOptions]
      for (let i = 0; i < allSupplierOptionC.length; i++) {
        allSupplierOptionC[i].selected = true
      }
      setAllSupplierOptions(allSupplierOptionC)
      return
    }
    if (baseData?.suppliers) {
      baseData.suppliers.forEach((supplier) => {
        const supGroups = getGroupName(false, supplier.G24, supplier.flavour)
        for (let i = 0; i < supGroups.length; i++) {
          for (let j = 0; j < newGroups.length; j++) {
            if (supGroups[i] === newGroups[j]) {
              newSupplierIds.push(supplier.id)
              break
            }
          }
        }
      })
      const allSupplierOptionC = [...allSupplierOptions]
      for (let i = 0; i < allSupplierOptionC.length; i++) {
        allSupplierOptionC[i].selected = newSupplierIds.includes(
          allSupplierOptionC[i].id
        )
      }
      setAllSupplierOptions(allSupplierOptionC)
    }
  }

  const handleSortOrder = () => {
    const selectedSupplierIds = allSupplierOptions
      .filter((v) => v.selected)
      .map((v) => v.id)
    let roundDetails: Maybe<RoundCreateInput> = null
    if (createNewRound) {
      const startDateString = startDate?.format("YYYY-MM-DD")
      const endDateString = endDate?.format("YYYY-MM-DD")
      if (startDateString == null || endDateString == null) {
        enqueueSnackbar("Runden Start und End Datum angeben", {
          variant: "error",
        })
        return
      }
      const roundNumberParsed = parseInt(roundNumber ?? "")
      if (isNaN(roundNumberParsed)) {
        enqueueSnackbar(
          "Runden Nummer konnte nicht in eine Zahl umgewandelt werden",
          { variant: "error" }
        )
        return
      }
      const targetStatusParsed = parseInt(targetStatus)
      if (isNaN(targetStatusParsed)) {
        enqueueSnackbar(
          "Ziel Status konnte nicht in eine Zahl umgewandelt werden",
          { variant: "error" }
        )
        return
      }
      roundDetails = {
        roundName: roundName,
        startDate: startDateString,
        endDate: endDateString,
        round: roundNumberParsed,
        status: targetStatusParsed,
      }
    }
    setPreviewIsLoading(true)
    setLoadingAllOrders(true)
    fetchSortOrder(
      fetchStatus,
      targetStatus,
      false,
      onlySingle,
      createNewRound,
      splitSoldOut,
      selectedPlantTypeIds,
      selectedSupplierIds,
      onlyEmptyNotiz,
      paymentMethod,
      dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
      dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      roundDetails
    )
      .then((data) => {
        if (data !== undefined) {
          if (data.sortOrder.message.length > 0) {
            enqueueSnackbar(data.sortOrder.message, { variant: "success" })
          }
        }
      })
      .finally(() => {
        setPreviewOrders([])
        setAllOrders([])
        setFetchStatus("")
        setPreviewIsLoading(false)
        setLoadingAllOrders(false)
        if (statusRef.current !== null) {
          statusRef.current.focus()
        }
      })
  }

  const loadPreview = () => {
    const selectedSupplierIds = allSupplierOptions
      .filter((v) => v.selected)
      .map((v) => v.id)
    setPreviewIsLoading(true)
    fetchSortOrder(
      fetchStatus,
      "",
      true,
      onlySingle,
      createNewRound,
      splitSoldOut,
      selectedPlantTypeIds,
      selectedSupplierIds,
      onlyEmptyNotiz,
      paymentMethod,
      dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
      dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      null
    )
      .then((data) => {
        if (data !== undefined) {
          if (data.sortOrder.preview.length === 0) {
            enqueueSnackbar("Keine Artikel gefunden", { variant: "info" })
          }
          setPreviewOrders(data.sortOrder.preview)
        }
      })
      .finally(() => {
        setPreviewIsLoading(false)
      })
  }

  const handleStatusRef = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const val = statusRef.current?.value
      handleUpdateStatus(val ?? "")
    }
  }

  const handleUpdateStatus = (status: string) => {
    if (status === "") {
      setTimeout(() => {
        if (statusRef.current !== null) {
          statusRef.current.value = status
          statusRef.current.focus()
        }
      }, 200)
    }
    if (status !== undefined && status !== null && status !== "") {
      setPreviewOrders([])
      setLoadingAllOrders(true)
      fetchOrderAggregateByStatus(status)
        .then((data) => {
          if (data !== undefined) {
            setAllOrders(data.newOrdersByStatus.rows)
            setFetchStatus(status)
            if (statusRef.current !== null) {
              statusRef.current.value = status
              statusRef.current.focus()
            }
          }
        })
        .finally(() => {
          setLoadingAllOrders(false)
        })
    }
  }

  const resetTemplate = () => {
    setOnlySingle(false)
    setSplitSoldOut(true)
    setTargetStatus("")
    setFetchStatus("")
    setDateRange([null, null])
    setOnlyEmptyNotiz(false)
    setPaymentMethod("")
    setCreateNewRound(false)
    setSelectedGroups([])
    setSelectedPlantTypeIds([])
    setAllSupplierOptions(
      allSupplierOptions.map((option) => {
        return {
          ...option,
          selected: false,
        }
      })
    )
    setAllOrders([])
    setPreviewOrders([])
    handleUpdateStatus("")
  }

  const resetDateRange = () => {
    setDateRange([null, null])
  }

  const handleTemplateChange = (name: string) => {
    setTemplate(name)
    if (name === "1") {
      resetTemplate()
      return
    }
    // get template value and set state
    const tmpl = filterTemplates.find((ft) => ft.name === name)
    if (tmpl !== undefined) {
      const obj: Template = JSON.parse(tmpl.template)
      // check if template is valid
      setOnlySingle(obj.onlySingle)
      setSplitSoldOut(obj.splitSoldOut)
      setTargetStatus(obj.targetStatus)
      setDateRange([dayjs(obj.dateRange[0]), dayjs(obj.dateRange[1])])
      if (obj.dateRange[0] !== null && obj.dateRange[1] !== null) {
        setStartDate(dayjs(obj.dateRange[0]))
        setEndDate(dayjs(obj.dateRange[1]))
      } else {
        resetDateRange()
      }
      setOnlyEmptyNotiz(obj.onlyEmptyNotiz)
      setPaymentMethod(obj.paymentMethod)
      setCreateNewRound(obj.createNewRound)
      setSelectedGroups(obj.selectedGroups)
      setSelectedPlantTypeIds(obj.plantTypeIds)
      setAllSupplierOptions(
        allSupplierOptions.map((option) => {
          return {
            ...option,
            selected: obj.selectedSupplierIds.includes(option.id),
          }
        })
      )
      handleUpdateStatus(obj.fetchStatus)
    }
  }

  const handleOrderPlantDetail = (bdb: string, botname: string) => {
    ordersByStatusAndBdb(fetchStatus, bdb).then((data) => {
      if (data?.ordersByStatusAndBDB) {
        setCurrentBDB(bdb)
        setCurrentBotname(botname)
        setOrderPlantDetails(data.ordersByStatusAndBDB)
        setOrderDetailsForPlantIsOpen(true)
      }
    })
  }

  const handleSaveFilterTemplate = (name: string) => {
    const obj: Template = {
      dateRange: dateRange,
      fetchStatus: fetchStatus,
      targetStatus: targetStatus,
      plantTypeIds: selectedPlantTypeIds,
      onlySingle: onlySingle,
      splitSoldOut: splitSoldOut,
      onlyEmptyNotiz: onlyEmptyNotiz,
      createNewRound: createNewRound,
      paymentMethod: paymentMethod,
      selectedGroups: selectedGroups,
      selectedSupplierIds: allSupplierOptions
        .filter((v) => v.selected)
        .map((v) => v.id),
    }
    const jsonTemplate = JSON.stringify(obj)
    saveFilterTemplate(name, jsonTemplate).then((data) => {
      if (data?.saveFilterTemplate) {
        enqueueSnackbar("Filter Template gespeichert", {
          variant: "success",
        })
        // replace template in filterTemplates
        const newTemplates = filterTemplates.map((ft) => {
          if (ft.name === name) {
            return {
              name: name,
              template: jsonTemplate,
            }
          }
          return ft
        })
        if (newTemplates.find((ft) => ft.name === name) === undefined) {
          newTemplates.push({
            name: name,
            template: jsonTemplate,
          })
        }
        setFilterTemplates(newTemplates)
        if (filterDialogIsOpen === true) {
          setFilterDialogIsOpen(false)
        }
        setTemplate(name)
        setNewFilterTemplateName("")
      }
    })
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="Status"
            value={fetchStatus}
            onChange={(e) => setFetchStatus(e.target.value)}
            variant="outlined"
            onKeyUp={handleStatusRef}
            inputRef={statusRef}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            label="Ziel Status"
            variant="outlined"
            error={targetStatus === "" || !/^\d+$/.test(targetStatus)}
            value={targetStatus}
            onChange={(e) => setTargetStatus(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel id="simple-select-filter-template">
              Filter Template
            </InputLabel>
            <Select
              labelId="simple-select-filter-template"
              id="simple-select-filter-template"
              value={template}
              label="Filter Template"
              onChange={(e) => handleTemplateChange(e.target.value as string)}
            >
              <MenuItem value={"1"}>Neues Template</MenuItem>
              {filterTemplates.map((ft) => (
                <MenuItem key={ft.name} value={ft.name}>
                  {ft.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            sx={{ height: "100%" }}
            onClick={() => {
              if (template === "1") {
                setFilterDialogIsOpen(true)
                return
              }
              handleSaveFilterTemplate(template)
            }}
          >
            Filter Template speichern
          </Button>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Button
            variant="contained"
            fullWidth
            color="error"
            sx={{ height: "100%" }}
            onClick={() => {
              if (template === "1") {
                return
              }
              deleteFilterTemplate(template).then((data) => {
                if (data !== undefined) {
                  setFilterTemplates(
                    filterTemplates.filter((ft) => ft.name !== template)
                  )
                  setTemplate("1")
                }
              })
            }}
          >
            Filter Template löschen
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <DateRangePicker
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
            }}
            localeText={{ start: "Zieldatum von", end: "Zieldatum bis" }}
            label="Date Range"
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={resetDateRange}
          >
            Datum zurücksetzen
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="simple-select-paymentmethods">Zahlart</InputLabel>
            <Select
              labelId="simple-select-paymentmethods"
              id="simple-select-paymentmethods"
              value={paymentMethod}
              label="Zahlart"
              onChange={(pm) => setPaymentMethod(pm.target.value)}
            >
              {paymentMethods.map((pm) => (
                <MenuItem key={pm.key} value={pm.value}>
                  {pm.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          {baseData && (
            <Autocomplete
              disablePortal
              id="article-groups-autocomplete"
              multiple
              value={baseData.plantTypes
                .filter((plantType) => {
                  return selectedPlantTypeIds.includes(plantType.id)
                })
                .map((plantType) => {
                  return { id: plantType.id, label: plantType.name }
                })}
              options={baseData.plantTypes.map((plantType: PlantType) => {
                return { id: plantType.id, label: plantType.name }
              })}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, newValue) => {
                setSelectedPlantTypeIds(newValue.map((val) => val.id))
              }}
              renderInput={(params) => <TextField {...params} label="Typ" />}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            id="lieferanten-groups-autocomplete"
            multiple
            value={selectedGroups}
            onChange={(_, newValue) => {
              handleGroupChange(newValue)
            }}
            options={groupList}
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Lieferanten Gruppe" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {baseData && (
            <Autocomplete
              id="lieferanten-single-autocomplete"
              multiple
              fullWidth
              value={selectedSuppliers}
              options={allSupplierOptions}
              onChange={(_, newValue) => {
                setAllSupplierOptions(
                  allSupplierOptions.map((option) => {
                    return {
                      ...option,
                      selected: newValue.some((v) => v.id === option.id),
                    }
                  })
                )
              }}
              getOptionLabel={(option) => option.id + " - " + option.label}
              renderInput={(params) => (
                <TextField {...params} label="Lieferanten" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <FormGroup
            sx={{
              display: { sm: "inline", md: "block" },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlySingle}
                  onChange={(_, checked) => {
                    setOnlySingle(checked)
                  }}
                />
              }
              label="Nur Singles"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitSoldOut}
                  onChange={(_, checked) => {
                    setSplitSoldOut(checked)
                  }}
                />
              }
              label="Ausverkaufte Artikel aufsplitten"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyEmptyNotiz}
                  onChange={(_, checked) => {
                    setOnlyEmptyNotiz(checked)
                  }}
                />
              }
              label="Nur mit leerer Notiz"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={createNewRound}
                  onChange={(_, checked) => {
                    setCreateNewRound(checked)
                  }}
                />
              }
              label="Neue Runde miterstellen"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              allOrders.length === 0 || previewIsLoading || loadingAllOrders
            }
            onClick={loadPreview}
          >
            Preview laden
          </Button>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setCommitSortOrderIsOpen(true)}
            disabled={
              allOrders.length === 0 || previewIsLoading || loadingAllOrders
            }
          >
            Sortieren
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginBottom: 5 }}>
          <Box sx={{ height: "50vh", width: "100%" }}>
            <Typography variant="h6">
              Bestellungen mit Status: {fetchStatus}
            </Typography>
            <DataGridPro
              rows={allOrders}
              getRowId={(row) => row.bdb}
              columns={cols}
              onRowClick={(row) => {
                handleOrderPlantDetail(row.row.bdb, row.row.botname)
              }}
              localeText={dataGridDEde}
              loading={loadingAllOrders}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginBottom: 5 }}>
          <Box sx={{ height: "50vh", width: "100%" }}>
            <Typography variant="h6">Preview</Typography>
            <DataGridPro
              rows={previewOrders}
              loading={previewIsLoading}
              getRowId={(row) => row.bdb}
              onRowClick={(row) => {
                handleOrderPlantDetail(row.row.bdb, row.row.botname)
              }}
              columns={cols}
              localeText={dataGridDEde}
            />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        onClose={handleCloseCommitSortOrder}
        open={commitSortOrderIsOpen}
      >
        <DialogTitle>Sortieren</DialogTitle>
        <DialogContent>
          <Typography>
            Möchten Sie die Sortierung wirklich durchführen?
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlySingle}
                  onChange={(_, checked) => {
                    setOnlySingle(checked)
                  }}
                />
              }
              label="Nur Singles"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={splitSoldOut}
                  onChange={(_, checked) => {
                    setSplitSoldOut(checked)
                  }}
                />
              }
              label="Ausverkaufte Artikel aufsplitten"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={onlyEmptyNotiz}
                  onChange={(_, checked) => {
                    setOnlyEmptyNotiz(checked)
                  }}
                />
              }
              label="Nur mit leerer Notiz"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={createNewRound}
                  onChange={(_, checked) => {
                    setCreateNewRound(checked)
                  }}
                />
              }
              label="Neue Runde miterstellen"
            />
          </FormGroup>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} sx={gridTileCss}>
              <Typography variant="h6" component="div">
                Ziel Status
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={gridTileCss}>
              <TextField
                fullWidth
                label="Ziel Status"
                variant="outlined"
                error={targetStatus === "" || !/^\d+$/.test(targetStatus)}
                value={targetStatus}
                onChange={(e) => setTargetStatus(e.target.value)}
              />
            </Grid>
          </Grid>
          {createNewRound && (
            <RoundCreateContent
              roundNumber={roundNumber}
              setRoundNumber={setRoundNumber}
              roundName={roundName}
              setRoundName={setRoundName}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={handleCloseCommitSortOrder}
          >
            Abbrechen
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSortOrder}
          >
            Sortieren
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={orderDetailsForPlantIsOpen}
        onClose={() => setOrderDetailsForPlantIsOpen(false)}
        TransitionComponent={TransitionSlideIn}
      >
        <Container maxWidth={false}>
          <Box sx={{ height: "90vh", width: "100%" }}>
            <Typography>Status: {fetchStatus}</Typography>
            <Typography>Botname: {currentBotname}</Typography>
            <Typography>BDB: {currentBDB}</Typography>
            <DataGridPro
              rows={orderPlantDetails}
              getRowId={(row) => row.orderID}
              columns={colsPlantOrderDetails}
              localeText={dataGridDEde}
              getRowHeight={() => "auto"}
              loading={loadingAllOrders}
            />
          </Box>
        </Container>
      </Dialog>

      <Dialog
        open={filterDialogIsOpen}
        onClose={() => {
          setFilterDialogIsOpen(false)
        }}
      >
        <DialogTitle>Filter Template speichern</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 1 }}
            fullWidth
            value={newFilterTemplateName}
            onChange={(e) => setNewFilterTemplateName(e.target.value)}
            label="Name"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={() => setFilterDialogIsOpen(false)}
          >
            Abbrechen
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              handleSaveFilterTemplate(newFilterTemplateName.trim())
            }
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default SortOrderPage
