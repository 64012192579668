import DeleteIcon from "@mui/icons-material/Delete"
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowOrderChangeParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid-pro"
import CurrencyTextField from "components/CurrencyTextField/CurrencyTextField"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { ArticleSupplier } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { articleSupplierDelete } from "queries/articleSupplierDelete"
import { articleSupplierSave } from "queries/articleSupplierSave"
import { articleSwapPrio } from "queries/articleSwapPrio"
import { useState } from "react"
import { supplier } from "./ArticleEditPage"

export interface ArticleSuppliersProps {
  articleBdb: string
  articleSuppliers: ArticleSupplier[]
  allSuppliers: supplier[]
  setArticleSuppliers: (articleSuppliers: ArticleSupplier[]) => void
  setIsLoadRequired: (isLoadRequired: boolean) => void
  setPrioOneSupplierChanged: (prioOneSupplierChanged: boolean) => void
  hasEditPermission: boolean
}

const getPrice = (params: GridValueGetterParams) => {
  const price = (params.row.price / 100).toFixed(2).replace(".", ",")
  return price
}

const setPrice = (params: GridValueSetterParams) => {
  const strVal = params.value.replace(/[^0-9,]/g, "").replace(",", ".")
  const numVal = Math.round(Number.parseFloat(strVal) * 100)
  if (isNaN(numVal) || numVal <= 0) {
    return { ...params.row, price: 1 }
  }
  return { ...params.row, price: numVal }
}

const parsePrice = (value: string) => {
  return value.replace(/[^0-9,.]/g, "").replace(".", ",")
}

const supplierCols: GridColDef<ArticleSupplier>[] = [
  {
    field: "supplierName",
    headerName: "Lieferant",
    sortable: false,
    editable: false,
    width: 240,
  },
  {
    field: "price",
    editable: true,
    sortable: false,
    headerName: "Preis (€)",
    valueGetter: (params) => getPrice(params),
    valueSetter: (params) => setPrice(params),
    valueParser: (value, _) => parsePrice(value),
    width: 120,
  },
  {
    field: "note",
    sortable: false,
    editable: true,
    headerName: "Notiz",
    width: 300,
  },
  //TODO: implement
  //{
  //field: "quality",
  //sortable: false,
  //editable: true,
  //headerName: "Qualität",
  //width: 150,
  //valueGetter: (params) => {
  //return params.row.quality.join(", ")
  //},
  //},
  {
    field: "createdAt",
    sortable: false,
    editable: false,
    headerName: "Erstellt am",
    type: "date",
    valueGetter: (params) => {
      return dayjs(params.value)
    },
    valueFormatter: (params) => {
      return params.value.format("DD.MM.YYYY HH:mm")
    },
    width: 175,
  },
  {
    field: "updatedAt",
    sortable: false,
    editable: false,
    headerName: "Zuletzt geändert",
    valueGetter: (params) => {
      return dayjs(params.value)
    },
    valueFormatter: (params) => {
      return params.value.format("DD.MM.YYYY HH:mm")
    },
    width: 175,
  },
  {
    field: "createdBy",
    sortable: false,
    editable: false,
    headerName: "Zuletzt geändert von",
    width: 150,
  },
]

interface articleSupplierCreateData {
  note: string
  price: number
  quality: string[]
  supplierID: number
}

const ArticleEditPageSuppliers = (
  props: ArticleSuppliersProps
): JSX.Element => {
  const {
    articleSuppliers,
    allSuppliers,
    setArticleSuppliers,
    articleBdb,
    setIsLoadRequired,
    setPrioOneSupplierChanged,
    hasEditPermission,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [supplierToDelete, setSupplierToDelete] = useState<number>(-1)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [supplierToAdd, setSupplierToAdd] = useState<articleSupplierCreateData>(
    {
      note: "",
      price: 0.01,
      supplierID: 0,
      quality: [],
    }
  )
  const [openAddSupplierModal, setOpenAddSupplierModal] =
    useState<boolean>(false)

  const handleDeleteSupplierClick = (id: number) => {
    setSupplierToDelete(id)
    setOpenDeleteModal(true)
  }

  const handleConfirmDeleteSupplier = async () => {
    const toDelete = articleSuppliers.find((as) => as.id === supplierToDelete)
    if (toDelete) {
      articleSupplierDelete({
        in: {
          id: toDelete.id,
          articleID: toDelete.articleID,
          note: toDelete.note,
          price: toDelete.price,
          priority: toDelete.priority,
          quality: toDelete.quality,
          supplierID: toDelete.supplierID,
          supplierName: toDelete.supplierName,
        },
      }).then((res) => {
        if (res?.articleSupplierDelete) {
          setArticleSuppliers(
            articleSuppliers.filter((as) => as.id !== supplierToDelete)
          )
          setIsLoadRequired(true)
          enqueueSnackbar("Lieferant erfolgreich entfernt", {
            variant: "success",
          })
          if (toDelete.priority === 1) {
            setPrioOneSupplierChanged(true)
          }
        }
      })
    }
    setOpenDeleteModal(false)
  }

  const handleCancelDeleteSupplierModal = () => {
    setSupplierToDelete(-1)
    setOpenDeleteModal(false)
  }

  const handleAddSupplierClick = () => {
    setOpenAddSupplierModal(true)
  }

  const handleConfirmAddSupplier = async () => {
    setIsLoading(true)
    articleSupplierSave({
      in: {
        id: 0,
        articleID: Number(articleBdb),
        note: supplierToAdd.note,
        price: supplierToAdd.price * 100,
        priority:
          articleSuppliers.length === 0
            ? 1
            : Math.max(...articleSuppliers.map((as) => as.priority)) + 1,
        quality: supplierToAdd.quality,
        supplierID: supplierToAdd.supplierID,
        supplierName: "not-needed",
      },
    }).then((res) => {
      if (res && res.articleSupplierSave !== undefined) {
        enqueueSnackbar("Lieferant gespeichert", {
          variant: "success",
        })
        const saved = res.articleSupplierSave
        setIsLoadRequired(true)
        setArticleSuppliers(articleSuppliers.concat(saved))
        setOpenAddSupplierModal(false)
        setSupplierToAdd({
          note: "",
          price: 0.01,
          supplierID: 0,
          quality: [],
        })
      }
      setIsLoading(false)
    })
  }

  const handleCancelAddSupplierModal = () => {
    setOpenAddSupplierModal(false)
  }

  const handleRowEditCommit = async (
    newRow: ArticleSupplier,
    oldRow: ArticleSupplier
  ): Promise<ArticleSupplier> => {
    setIsLoading(true)
    const r = await articleSupplierSave({
      in: {
        id: newRow.id,
        articleID: newRow.articleID,
        note: newRow.note,
        price: newRow.price,
        priority: newRow.priority,
        quality: newRow.quality,
        supplierID: newRow.supplierID,
        supplierName: newRow.supplierName,
      },
    })
    setIsLoading(false)
    if (r?.articleSupplierSave) {
      enqueueSnackbar("Änderungen erfolgreich gespeichert", {
        variant: "success",
      })
      setIsLoadRequired(true)
    } else {
      return oldRow
    }
    return newRow
  }

  const handleRowOrderChangeArticleSupplier = async (
    params: GridRowOrderChangeParams,
    e: any
  ) => {
    console.log(e)
    setIsLoading(true)
    const sourceItem = articleSuppliers[params.oldIndex]
    const targetItem = articleSuppliers[params.targetIndex]
    const r = await articleSwapPrio(targetItem.id, sourceItem.id)
    if (r?.articleSupplierSwapPriority === true) {
      enqueueSnackbar("Priorität erfolgreich gespeichert", {
        variant: "success",
      })
    } else {
      return
    }
    // update frontend state
    const oldPrio = articleSuppliers[params.oldIndex].priority
    const newPrio = articleSuppliers[params.targetIndex].priority

    const newArticleSupplier = [...articleSuppliers]
    const temp = newArticleSupplier[params.oldIndex]
    newArticleSupplier[params.oldIndex] = newArticleSupplier[params.targetIndex]
    newArticleSupplier[params.targetIndex] = temp

    newArticleSupplier[params.oldIndex].priority = oldPrio
    newArticleSupplier[params.targetIndex].priority = newPrio
    setArticleSuppliers(newArticleSupplier)
    setIsLoading(false)
    if (oldPrio === 1 || newPrio === 1) {
      setPrioOneSupplierChanged(true)
    }
  }

  return (
    <>
      <Typography variant="h6" component="div" gutterBottom>
        Lieferanten
      </Typography>
      <Box sx={{ height: "40vh", width: "100%" }}>
        <DataGridPro
          rows={articleSuppliers}
          rowReordering={hasEditPermission}
          disableColumnFilter
          processRowUpdate={handleRowEditCommit}
          onRowOrderChange={handleRowOrderChangeArticleSupplier}
          columns={[
            ...supplierCols,
            {
              field: " ",
              sortable: false,
              width: 50,
              renderCell: (params) => {
                return (
                  <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    disabled={!hasEditPermission}
                    onClick={(_) => {
                      handleDeleteSupplierClick(params.row.id)
                    }}
                    color="inherit"
                  />
                )
              },
            },
          ]}
          localeText={dataGridDEde}
          loading={isLoading}
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" paddingTop={5}>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasEditPermission}
          onClick={() => {
            handleAddSupplierClick()
          }}
        >
          Neuen Lieferant Hinzufügen
        </Button>
      </Box>

      <Dialog open={openDeleteModal} onClose={handleCancelDeleteSupplierModal}>
        <DialogTitle>Löschung bestätigen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sicher, dass Sie diesen Artikel nicht mehr von{" "}
            {articleSuppliers.find((as) => as.id === supplierToDelete)
              ?.supplierName ?? ""}{" "}
            beziehen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteSupplierModal} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleConfirmDeleteSupplier} color="error">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddSupplierModal}
        onClose={handleCancelAddSupplierModal}
      >
        <DialogTitle>Erstellung bestätigen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Autocomplete
                disablePortal
                options={allSuppliers}
                getOptionLabel={(option) => option.id + " - " + option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label="Lieferant" variant="outlined" />
                )}
                onChange={(_, newValue) => {
                  if (newValue)
                    setSupplierToAdd({
                      ...supplierToAdd,
                      supplierID: newValue.id,
                    })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CurrencyTextField
                label="Preis"
                value={"" + supplierToAdd.price}
                nullAllowed={false}
                setValueCallback={(v) =>
                  setSupplierToAdd({
                    ...supplierToAdd,
                    price: +v,
                  })
                }
                setIsValidCallback={(_) => {}}
                marginBottom={0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Notiz"
                value={supplierToAdd.note}
                onChange={(e) => {
                  setSupplierToAdd({
                    ...supplierToAdd,
                    note: e.target.value,
                  })
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelAddSupplierModal}
            color="error"
            variant="contained"
            fullWidth
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleConfirmAddSupplier}
            color="primary"
            variant="contained"
            fullWidth
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ArticleEditPageSuppliers
