import ContentCutIcon from "@mui/icons-material/ContentCut"
import { Box, Button, Typography } from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  GridSlotsComponentsProps,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { CollapsibleTable } from "components/CollapsibleTable/CollapsibleTable"
import { DialogSplitOrder } from "components/DialogSplitOrder/DialogSplitOrder"
import { OrderDetailsMore, OrderItem, SplitItemInput } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { fetchOrderSplit } from "queries/fetchOrderSplit"
import { useEffect, useState } from "react"
import { AddArticleDialog } from "./AddArticleDialog"

type OrderArticlesProps = {
  order: OrderDetailsMore
  setOrder: (arg: OrderDetailsMore) => void
  setOrderItemsChanged: (arg: boolean) => void
  reloadData: () => void
}

const isOrderEditable = (status: number): boolean => {
  if (status === 15 || status === 17) {
    enqueueSnackbar("Auftrag bereits storiniert!", { variant: "info" })
    return false
  }
  if (status === 3 || status === 10) {
    enqueueSnackbar("Auftrag bereits versendet!", { variant: "info" })
    return false
  }
  return true
}

const columnsAddresses: GridColDef<OrderItem>[] = [
  {
    field: "artnum",
    headerName: "BDB",
    width: 80,
    renderCell: (params) => {
      return (
        <Typography
          variant="body2"
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "primary.main",
          }}
          component="div"
          onClick={() =>
            window.open(
              `${window.location.origin}/#/editArticle/${params.row.artnum}`,
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          {params.row.artnum}
        </Typography>
      )
    },
  },
  {
    field: "botname",
    headerName: "Botname",
    width: 280,
  },
  {
    field: "size",
    headerName: "Größe",
    renderCell: (params) => {
      let vonBis = params.row.von + " - " + params.row.bis
      if (params.row.von === params.row.bis) vonBis = params.row.von + ""
      if (params.row.von === 0 && params.row.bis === 0) vonBis = ""

      let stuVonBis = params.row.stuvon + " - " + params.row.stubis
      if (params.row.stuvon === params.row.stubis)
        stuVonBis = params.row.stuvon + ""
      if (params.row.stuvon === 0 && params.row.stubis === 0) stuVonBis = ""
      return (
        <Box>
          <Typography variant="body2" component="div">
            {vonBis}
          </Typography>

          {params.row.stahoeh > 0 && (
            <Typography variant="body2" color="text.secondary" component="div">
              Stahoeh: {params.row.stahoeh}
            </Typography>
          )}

          {stuVonBis !== "" && (
            <Typography variant="body2" color="text.secondary" component="div">
              Stu: {stuVonBis}
            </Typography>
          )}
        </Box>
      )
    },
    width: 140,
  },
  {
    field: "anzahl",
    headerName: "Anzahl",
    width: 80,
  },

  {
    field: "stueckpreis",
    headerName: "Stückpreis",
    width: 100,
    valueGetter: (params) => getEuro(params.row.stueckpreis),
  },

  {
    field: "preis",
    headerName: "Gesamt",
    width: 100,
    valueGetter: (params) =>
      getEuro(params.row.stueckpreis * params.row.anzahl),
  },
  {
    field: "soldStatus",
    headerName: "Artikel Verfügbarkeit",
    width: 200,
  },
  {
    field: "bestellt",
    headerName: "Bestellt",
    width: 70,
    type: "boolean",
  },
  {
    field: "etikettiert",
    headerName: "Etikettiert",
    width: 120,
    type: "boolean",
  },
  {
    field: "verteilt",
    headerName: "Verteilt",
    width: 70,
    type: "boolean",
  },
  {
    field: "splitstatus",
    headerName: "Split",
    width: 70,
    type: "boolean",
  },
  {
    field: "lager",
    headerName: "Lager",
    width: 200,
  },
]

const getEuro = (betrag: number) => {
  return betrag.toFixed(2) + " €"
}

export const OrderArticles = (props: OrderArticlesProps) => {
  const { order, setOrder, setOrderItemsChanged, reloadData } = { ...props }
  const [orderArticles, setOrderArticles] = useState<OrderItem[]>(
    order.orderItems
  )
  const [selectedArticles, setSelectedArticles] = useState<number[]>([])
  const [openChangeArticleDialog, setOpenChangeArticleDialog] = useState(false)
  const [openSplitDialog, setOpenSplitDialog] = useState(false)
  const [splitStatus, setSplitStatus] = useState<number>(1)

  useEffect(() => {
    setOrderArticles(order.orderItems)
  }, [order.orderItems])

  const getSplitAbleArticle = () => {
    const splitAbleArticles: SplitItemInput[] = []
    for (let i = 0; i < orderArticles.length; i++) {
      if (
        selectedArticles.includes(orderArticles[i].ewID) &&
        !orderArticles[i].splitstatus
      ) {
        splitAbleArticles.push({
          orderId: order.orderID,
          articleId: orderArticles[i].artnum,
          custId: order.kundnum,
        })
      }
    }
    return splitAbleArticles
  }

  const splitOrder = () => {
    const isEditable = isOrderEditable(order.status)
    if (!isEditable) return
    const splitAbleArticles = getSplitAbleArticle()
    if (splitAbleArticles?.length === 0) {
      enqueueSnackbar(
        "Keine Artikel ausgewählt oder Artikel bereits gesplittet",
        {
          variant: "warning",
        }
      )
    } else {
      setOpenSplitDialog(true)
    }
  }

  const splittingOrder = () => {
    const splitAbleArticles = getSplitAbleArticle()
    if (splitAbleArticles) {
      fetchOrderSplit(splitStatus, [], splitAbleArticles)
        .then((data) => {
          if (data) {
            enqueueSnackbar("Artikel gesplittet", {
              variant: "info",
            })
          }
        })
        .finally(() => {
          reloadData()
        })
    }
    setOpenSplitDialog(false)
    setSelectedArticles([])
  }

  const openAddArticleArticles = () => {
    const isEditable = isOrderEditable(order.status)
    if (!isEditable) return
    setOpenChangeArticleDialog(true)
  }

  const closeAddArticleDialog = () => {
    setOpenChangeArticleDialog(false)
  }

  const checkArticleEditble = (): boolean => {
    for (let item of orderArticles) {
      if (item.etikettiert) return false
    }
    return true
  }

  const CustomToolbar = (
    props: NonNullable<GridSlotsComponentsProps["toolbar"]>
  ) => {
    const { splitClick } = props

    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <Box>
          <Button
            variant="contained"
            startIcon={<ContentCutIcon />}
            onClick={splitClick}
            sx={{ m: 1 }}
          >
            Auftrag splitten
          </Button>

          <Button
            variant="contained"
            disabled={!checkArticleEditble()}
            onClick={openAddArticleArticles}
            sx={{ m: 1 }}
          >
            Artikel ändern
          </Button>
        </Box>
      </GridToolbarContainer>
    )
  }

  return (
    <Box>
      <CollapsibleTable
        defaultOpen={true}
        title={`Artikel (${orderArticles.length})`}
      >
        <Box sx={{ height: 600 }}>
          <DataGridPro
            getRowId={(rows) => rows.ewID}
            rows={orderArticles}
            getRowHeight={() => "auto"}
            columns={columnsAddresses}
            getRowClassName={(params) => {
              if (params.row.splitstatus === true) {
                return "splitStatus"
              }
              return ""
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                splitClick: splitOrder,
              },
            }}
            checkboxSelection
            onRowSelectionModelChange={(row) =>
              setSelectedArticles(row as number[])
            }
          />
        </Box>
      </CollapsibleTable>
      <AddArticleDialog
        order={order}
        setOrder={setOrder}
        setOrderChanged={setOrderItemsChanged}
        open={openChangeArticleDialog}
        close={closeAddArticleDialog}
      />
      <DialogSplitOrder
        open={openSplitDialog}
        close={() => setOpenSplitDialog(false)}
        splitOrder={splittingOrder}
        articles={selectedArticles}
        splitStatus={splitStatus}
        setSplitStatus={setSplitStatus}
      />
    </Box>
  )
}
