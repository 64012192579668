import { Refresh } from "@mui/icons-material"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material"

import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { DateRangePicker } from "@mui/x-date-pickers-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs from "dayjs"
import { CustomerRefund } from "generated/graphql"
import { enqueueSnackbar } from "notistack"
import { customerOrderSettle, getCustomerRefund } from "queries/customerRefund"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { germanDateFormatter } from "utils/datefmt"
import { shortcutsItems } from "utils/shortcutItems"

const getEuro = (betrag: number) => {
  return betrag.toFixed(2) + " €"
}

export const RefundListPage = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [refundData, setRefundData] = useState<CustomerRefund[]>([])
  const [showSettled, setShowSettled] = useState<boolean>(false)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(100)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [fromDate, setFromDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(30, "day")
  )
  const [debouncedFromDate, setDebouncedFromDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(30, "day")
  )
  const [toDate, setToDate] = useState<dayjs.Dayjs>(dayjs())
  const [debouncedToDate, setDebouncedToDate] = useState<dayjs.Dayjs>(dayjs())
  const navigate = useNavigate()
  const refundDataFiltered = useMemo(() => {
    return refundData.filter((item) => {
      if (showSettled) {
        return item
      } else {
        return !item.settledBy
      }
    })
  }, [refundData, showSettled])

  const fetchRefundsData = useCallback(async () => {
    setLoading(true)
    const res = await getCustomerRefund(
      pageSize,
      currentPage * pageSize,
      fromDate,
      toDate,
      showSettled
    )
    if (res?.customerRefunds) {
      setTotalRows(res.customerRefunds.totalRows)
      setRefundData(res.customerRefunds.rows)
    }
    setLoading(false)
  }, [currentPage, fromDate, pageSize, toDate, showSettled])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setFromDate(debouncedFromDate)
      setToDate(debouncedToDate)
    }, 500)
    return () => {
      clearTimeout(timerId)
    }
  }, [debouncedFromDate, debouncedToDate])

  useEffect(() => {
    fetchRefundsData()
  }, [fetchRefundsData])

  const handleSettleClick = async (id: number) => {
    setLoading(true)
    const res = await customerOrderSettle(id)
    if (res?.customerRefundSettle) {
      enqueueSnackbar("Rücküberweisung erfolgreich", { variant: "success" })
      setRefundData((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return res.customerRefundSettle
          }
          return item
        })
      )
    } else {
      enqueueSnackbar("Fehler beim Setzen der Rücküberweisung", {
        variant: "error",
      })
    }
    setLoading(false)
  }

  const columnRefunds: GridColDef<CustomerRefund>[] = [
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "orderID",
      headerName: "Bestell ID",
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() => navigate("/orderDetails/" + params.row.orderID)}
          >
            {params.row.orderID}
          </Typography>
        )
      },
    },
    {
      field: "customerID",
      headerName: "Kunde ID",
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "primary.main",
            }}
            component="div"
            onClick={() => navigate("/editCustomer/" + params.row.customerID)}
          >
            {params.row.customerID}
          </Typography>
        )
      },
    },
    { field: "articleID", headerName: "Artikel ID" },
    {
      field: "amount",
      headerName: "Betrag",
      valueGetter: (params) => getEuro(params.row.amount / 100), //TODO: if amount is in EUR-Cent
    },
    {
      field: "note",
      headerName: "Detail",
      width: 600,
      renderCell: (params) => (
        <Typography
          variant="body2"
          component="div"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "angelegt am",
      width: 150,
      type: "date",
      valueGetter: (params) => {
        return dayjs(params.row.createdAt)
      },
      valueFormatter: (params) => {
        return germanDateFormatter.format(params.value)
      },
    },
    { field: "createdBy", headerName: "angelegt von" },
    {
      field: "settledAt",
      headerName: "erledigt am",
      width: 150,
      type: "date",
      valueGetter: (params) => {
        if (
          params.value === null ||
          params.value === undefined ||
          params.value === ""
        ) {
          return null
        }
        return dayjs(params.row.settledAt)
      },
      valueFormatter: (params) => {
        if (params.value !== null) {
          return germanDateFormatter.format(params.value)
        } else {
          return null
        }
      },
    },
    { field: "settledBy", headerName: "erledigt von" },
    {
      field: "action",
      headerName: "Aktion",
      width: 100,
      renderCell: (params) => (
        <Button
          fullWidth
          variant="contained"
          disabled={params.row.settledBy ? true : false}
          sx={{
            textTransform: "none",
            backgroundColor: "secondary.main",
            "&.Mui-disabled": {
              backgroundColor: "primary.main",
              color: "white",
            },
          }}
          onClick={() => handleSettleClick(params.row.id)}
        >
          {params.row.settledBy ? (
            <TaskAltIcon fontSize="small" />
          ) : (
            <RadioButtonUncheckedIcon fontSize="small" />
          )}
        </Button>
      ),
    },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        marginTop: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            startIcon={<Refresh />}
            sx={{ textTransform: "none" }}
            onClick={() => {
              fetchRefundsData()
            }}
          >
            Aktualisieren
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSettled}
                  onChange={(e) => setShowSettled(e.target.checked)}
                />
              }
              label="Erledigt anzeigen"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={3}>
          <DateRangePicker
            localeText={{ start: "Von", end: "Bis" }}
            value={[debouncedFromDate, debouncedToDate]}
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
            }}
            onChange={(newValue) => {
              if (newValue === null) return
              if (newValue[0] === null || newValue[1] === null) return
              setDebouncedFromDate(newValue[0])
              setDebouncedToDate(newValue[1])
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "75vh", width: "100%" }}>
            <DataGridPro
              rows={refundDataFiltered}
              loading={loading}
              columns={columnRefunds}
              pageSizeOptions={[100, 200, 500]}
              rowCount={totalRows}
              paginationMode="server"
              onPaginationModelChange={(params) => {
                setPageSize(params.pageSize)
                setCurrentPage(params.page)
              }}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              getRowId={(row) => row.id}
              pagination
              slots={{
                toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
