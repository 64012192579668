import { LoadingButton } from "@mui/lab"
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import { useAppSelector } from "app/hooks"
import { selectCurrentRound } from "app/roundSlice"
import RoundOrderAddItem from "components/RoundOrderAddItem/RoundOrderAddItem"
import { RoundOrderDeleteItemDialog } from "components/RoundOrderDeleteDialog/RoundOrderDeleteDialog"
import {
  EditSup,
  RoundOrderMoveItemDialog,
} from "components/RoundOrderMoveItemDialog/RoundOrderMoveItemDialog"
import { RoundOrderSplitItemDialog } from "components/RoundOrderSplitItemDialog/RoundOrderSplitItemDialog"
import SupplierSelector from "components/SupplierSelector/SupplierSelector"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs, { Dayjs } from "dayjs"
import {
  ArticleSupplierCrossRef,
  RoundItemAddInput,
  RoundItemEditDescriptionInput,
  RoundItemEditInput,
  RoundOrderArticle,
  SupplierOrderItem,
  SupplierRoundInfo,
} from "generated/graphql"
import { sortedUniqBy } from "lodash"
import { enqueueSnackbar } from "notistack"
import { ArticleItemWithCount } from "pages/AricleSearchRoundAdd/ArticleSearchRoundAdd"
import { fetchRoundOrders } from "queries/fetchRoundOrders"
import { roundItemEditDescription } from "queries/roundItemEditDescription"
import { roundItemsAdd } from "queries/roundItemsAdd"
import { roundItemsEdit } from "queries/roundItemsEdit"
import { roundItemsSplit } from "queries/roundItemsSplit"
import {
  supplierRoundInfoGet,
  supplierRoundInfoSave,
} from "queries/supplierRoundInfo"
import {
  roundlistItemDelete,
  supplierRoundInfo,
  supplierRoundItemMove,
} from "queries/supplierRoundOrder"
import { KeyboardEvent, useEffect, useState } from "react"

interface supplier {
  id: number
  name: string
}

const colDefs: GridColDef<RoundOrderArticle>[] = [
  {
    field: "position",
    headerName: "Posten",
  },
  {
    field: "botName",
    headerName: "Artikel",
    renderCell: (params) => {
      return (
        <Box>
          <Typography variant="body1" component="div">
            {params.row.botName}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="div">
            {params.row.variant}
          </Typography>
        </Box>
      )
    },
    width: 350,
  },
  {
    field: "articleId",
    headerName: "BDB",
    width: 150,
    renderCell: (params) => {
      return (
        <Link href={`/#/editArticle/${params.row.articleId}`}>
          <Typography variant="body2" color="secondary" component="div">
            {params.row.articleId}
          </Typography>
        </Link>
      )
    },
  },
  {
    field: "quantityTotal",
    headerName: "Anzahl",
    width: 150,
    type: "number",
    editable: true,
  },
  {
    field: "size",
    headerName: "Größe",
    valueGetter: (params) => {
      if (!params.row.sizeMin || !params.row.sizeMax) return ""
      if (params.row.sizeMin === params.row.sizeMax) return params.row.sizeMin
      return params.row.sizeMin + " - " + params.row.sizeMax
    },
    width: 160,
  },
  { field: "supplierContainerSize", headerName: "Lieferform", width: 200 },
  {
    field: "description",
    headerName: "Beschreibung",
    width: 200,
    editable: true,
  },
]

const getGermanWeekDay = (day: number): string => {
  switch (day) {
    case 0:
      return "Sonntag"
    case 1:
      return "Montag"
    case 2:
      return "Dienstag"
    case 3:
      return "Mittwoch"
    case 4:
      return "Donnerstag"
    case 5:
      return "Freitag"
    case 6:
      return "Samstag"
    default:
      return ""
  }
}

const RoundOrderPage = (): JSX.Element => {
  const apiRef = useGridApiRef()
  const selectedRound = useAppSelector(selectCurrentRound)
  const [roundData, setRoundData] = useState<RoundOrderArticle[]>([])
  const [roundSuppliers, setRoundSuppliers] = useState<supplier[]>([])
  const [selectedSupplier, setSelectedSupplier] = useState<supplier | null>(
    null
  )
  const [roundOrderCount, setRoundOrderCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [supplierRoundInfos, setSupplierRoundInfos] = useState<
    SupplierRoundInfo[]
  >([])
  const [selectedNoteRequiresCollection, setSelectedNoteRequiresCollection] =
    useState<boolean>(true)
  const [selectedNoteText, setSelectedNoteText] = useState<string>("")
  const [selectedCollectionDate, setSelectedCollectionDate] = useState<Dayjs>(
    dayjs()
  )
  const [selectedCollectionTime, setSelectedCollectionTime] =
    useState<Dayjs | null>(null)

  const [reloadToggle, setReloadToggle] = useState<boolean>(false)
  // add items
  const [addItemIsOpen, setAddItemIsOpen] = useState<boolean>(false)
  const [selection, setSelection] = useState<RoundOrderArticle[]>([])
  // split items
  const [splitItemIsOpen, setSplitItemIsOpen] = useState<boolean>(false)
  const [supplierOrderInfo, setSupplierOrderInfo] = useState<
    SupplierOrderItem[]
  >([])
  // postpone
  const [moveItemIsOpen, setMoveItemIsOpen] = useState<boolean>(false)
  const [supplierItemCrossRefs, setSupplierItemCrossRefs] = useState<
    ArticleSupplierCrossRef[]
  >([])
  //delete items
  const [deleteItemIsOpen, setDeleteItemIsOpen] = useState<boolean>(false)
  const reloadData = () => {
    setReloadToggle(!reloadToggle)
  }

  // round data load
  useEffect(() => {
    setIsLoading(true)
    if (selectedRound) {
      const scrollPosition = apiRef.current.getScrollPosition()
      fetchRoundOrders(selectedRound.round)
        .then((roundOrderData) => {
          if (roundOrderData?.roundOrdersGet) {
            setRoundOrderCount(roundOrderData.roundOrdersGet.totalOrdersCount)
            setRoundData(roundOrderData.roundOrdersGet.orderArticles)
            const suppliers = sortedUniqBy(
              roundOrderData.roundOrdersGet.orderArticles.map((order) => {
                return { id: order.supplierID, name: order.supplierName }
              }),
              (supplier) => supplier.id
            )
            setRoundSuppliers(suppliers)
            if (selectedSupplier !== null && suppliers.length > 0) {
              setSelectedSupplier(
                suppliers.find((s) => s.name === selectedSupplier.name) ??
                  suppliers[0]
              )
            } else if (suppliers.length > 0) {
              setSelectedSupplier(suppliers[0])
            }
          }
        })
        .then(() => {
          supplierRoundInfoGet(selectedRound.round).then((noteData) => {
            if (noteData?.supplierRoundInfos) {
              setSupplierRoundInfos(noteData.supplierRoundInfos)
            }
          })
        })
        .finally(() => {
          setIsLoading(false)
          apiRef.current.scroll(scrollPosition)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRound, reloadToggle])

  // load selected note & collection date
  useEffect(() => {
    if (selectedSupplier && supplierRoundInfos) {
      const selectedNote = supplierRoundInfos.find(
        (n) => n.supplierId === selectedSupplier.id
      )
      setSelectedNoteText(selectedNote?.note ?? "")
      setSelectedCollectionDate(dayjs(selectedNote?.collectionDate) ?? dayjs())
      setSelectedCollectionTime(
        selectedNote?.collectionTime
          ? dayjs(selectedNote?.collectionTime, "HH:mm")
          : null
      )
    }
  }, [selectedSupplier, supplierRoundInfos, selectedRound])

  const handleNoteKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      saveNote(dayjs(), "date")
    }
  }

  const handleRoundQuantityItemEdit = (articleId: number, count: number) => {
    if (!selectedRound || !selectedSupplier) {
      return
    }
    const item: RoundItemEditInput = {
      round: selectedRound.round,
      articleID: articleId,
      supplierID: selectedSupplier.id,
      count: count,
    }
    roundItemsEdit(item).then((res) => {
      if (res?.roundItemsEdit) {
        enqueueSnackbar("Artikel geändert", { variant: "success" })
      }
    })
  }

  const handleRoundDescriptionItemEdit = (
    articleId: number,
    description: string
  ) => {
    if (!selectedRound || !selectedSupplier) {
      return
    }
    const item: RoundItemEditDescriptionInput = {
      round: selectedRound.round,
      articleID: articleId,
      supplierID: selectedSupplier.id,
      description: description,
    }
    roundItemEditDescription(item).then((res) => {
      if (res?.roundItemEditDescription) {
        enqueueSnackbar("Artikel geändert", { variant: "success" })
        const roundDataCopy = [...roundData]
        for (let i = 0; i < roundDataCopy.length; i++) {
          if (
            roundDataCopy[i].articleId === String(articleId) &&
            roundDataCopy[i].supplierName === selectedSupplier.name
          ) {
            roundDataCopy[i].description = description
            setRoundData(roundDataCopy)
            break
          }
        }
      }
    })
  }

  const saveNote = (e: Dayjs, type: "date" | "time") => {
    if (!selectedSupplier) return
    if (!selectedRound) return
    if (e === null || e === undefined || !e.isValid) return

    let colDate: string = ""
    let note: string = selectedNoteText
    if (type === "date") {
      colDate = e.format("YYYY-MM-DD")
      note = `Bestellung zu ${getGermanWeekDay(e.day())} (${e.format(
        "DD.MM.YYYY"
      )})`
    } else {
      colDate = selectedCollectionDate.format("YYYY-MM-DD")
    }

    let colTime: string | null = null
    if (type === "time") {
      colTime = e.format("HH:mm")
    } else {
      colTime = selectedCollectionTime
        ? selectedCollectionTime.format("HH:mm")
        : null
    }

    supplierRoundInfoSave({
      in: {
        round: selectedRound.round,
        supplierID: selectedSupplier.id,
        note: note,
        requiresCollection: selectedNoteRequiresCollection,
        collectionDate: colDate,
        collectionTime: colTime,
      },
    })
      .then((res) => {
        if (res?.supplierRoundInfoSave) {
          enqueueSnackbar("Notiz gespeichert", { variant: "success" })
          setSelectedNoteText(note)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleRoundItemAdd = (
    selectedItems: ArticleItemWithCount[],
    supplierId: number
  ) => {
    if (!selectedRound) return
    const items: RoundItemAddInput[] = selectedItems.map((x) => {
      return {
        round: selectedRound.round,
        articleID: Number(x.bdb),
        supplierID: supplierId,
        count: x.count,
      }
    })
    setIsLoading(true)
    roundItemsAdd(items).then((res) => {
      if (res?.roundItemsAdd) {
        enqueueSnackbar("Artikel hinzugefügt", { variant: "success" })
        reloadData()
      }
    })
  }

  const handleSplitItemDialogOpen = () => {
    if (!selectedSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, selectedSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setSplitItemIsOpen(true)
        })
    }
  }

  const handleRoundItemsSplit = (
    items: SupplierOrderItem[],
    statusCode: number,
    mergeStatuses: number[]
  ) => {
    if (!selectedRound) return
    setIsLoading(true)
    roundItemsSplit({
      in: {
        roundListIds: items.map((x) => x.id),
        statusCode: statusCode,
        mergeStatuses: mergeStatuses,
      },
    })
      .then((res) => {
        if (res?.roundItemsSplit) {
          if (res.roundItemsSplit.message) {
            enqueueSnackbar(res.roundItemsSplit.message, {
              variant: "info",
              persist: true,
            })
          } else {
            enqueueSnackbar("Artikel gesplittet", { variant: "success" })
          }
          reloadData()
        }
      })
      .finally(() => {
        setSplitItemIsOpen(false)
        setIsLoading(false)
      })
  }

  const handleMoveItemDialogOpen = () => {
    if (!selectedSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, selectedSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
          if (data?.articleSupplierRefs) {
            setSupplierItemCrossRefs(data.articleSupplierRefs)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setMoveItemIsOpen(true)
        })
    }
  }

  const handleMoveItems = async (data: EditSup[]) => {
    if (!selectedRound) return
    if (!selectedSupplier) return
    setIsLoading(true)
    for (let i = 0; i < data.length; i++) {
      if (data[i].newSupplierId === selectedSupplier.id) {
        // skip wrong input
        continue
      }
      // push to the server if ONE fails QUIT
      const res = await supplierRoundItemMove(
        data[i].roundlistId,
        selectedRound.round,
        data[i].newSupplierId,
        data[i].quantity
      )
      if (res?.supplierRoundItemMove !== true) {
        enqueueSnackbar("Fehler beim Verschieben", { variant: "error" })
        break
      }
    }
    reloadData()
    setMoveItemIsOpen(false)
    setIsLoading(false)
  }

  const handleDeleteItemDialogOpen = () => {
    if (!selectedSupplier) return
    if (!selectedRound) return
    if (selection.length > 0) {
      setIsLoading(true)
      supplierRoundInfo(selectedRound.round, selectedSupplier.id)
        .then((data) => {
          if (data?.articleSupplierInfo) {
            setSupplierOrderInfo(data.articleSupplierInfo)
          }
          if (data?.articleSupplierRefs) {
            setSupplierItemCrossRefs(data.articleSupplierRefs)
          }
        })
        .finally(() => {
          setIsLoading(false)
          setDeleteItemIsOpen(true)
        })
    }
  }

  const handleDeleteItems = async (data: number[], callback: () => void) => {
    if (!selectedRound) return
    if (!selectedSupplier) return
    const res = await roundlistItemDelete(data)
    if (res?.supplierRoundItemDelete !== true) {
      enqueueSnackbar("Fehler beim Löschen", { variant: "error" })
      return
    }
    reloadData()
  }

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    if (!selectedSupplier) return
    const currentSupplierItems = roundData.filter(
      (x) => x.supplierName === selectedSupplier.name
    )
    const l: RoundOrderArticle[] = []
    for (const item of currentSupplierItems) {
      if (
        selectionModel.includes(selectedSupplier.name + "_" + item.articleId)
      ) {
        l.push(item)
      }
    }
    setSelection(l)
  }

  const handleSupplierChange = (supplierName: string) => {
    const supplier = roundSuppliers.find((s) => s.name === supplierName)
    if (supplier) {
      setSelectedSupplier(supplier)
      apiRef.current.scroll({ top: 0, left: 0 })
    }
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SupplierSelector
            items={roundSuppliers.map((supplier) => supplier.name)}
            selectedItem={selectedSupplier ? selectedSupplier.name : ""}
            handleSelectionChange={handleSupplierChange}
          />
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            onClick={() => {
              setAddItemIsOpen(true)
            }}
            disabled={!selectedSupplier}
            loading={isLoading}
          >
            Hinzufügen
          </LoadingButton>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            onClick={handleSplitItemDialogOpen}
            disabled={selection.length === 0}
            loading={isLoading}
          >
            ({selection.length}) Splitten
          </LoadingButton>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            onClick={handleMoveItemDialogOpen}
            disabled={selection.length === 0 || selection.length > 1}
            loading={isLoading}
          >
            ({selection.length}) Verschieben
          </LoadingButton>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            sx={{ height: "100%" }}
            fullWidth
            onClick={handleDeleteItemDialogOpen}
            disabled={selection.length === 0}
          >
            ({selection.length}) Entfernen
          </LoadingButton>
        </Grid>
        <Grid item xs={2}>
          <FormGroup>
            <FormControlLabel
              value="Abholung?"
              label="Abholung?"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={selectedNoteRequiresCollection}
                  onChange={(e) => {
                    setSelectedNoteRequiresCollection(e.target.checked)
                    if (!e.target.checked) {
                      setSelectedNoteText("")
                      setSelectedCollectionTime(null)
                    }
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Information den Lieferanten-Bestellung zuweisen mit Enter senden"
            value={selectedNoteText}
            onChange={(e) => {
              setSelectedNoteText(e.target.value)
            }}
            onKeyUp={(e) => {
              handleNoteKeyUp(e)
            }}
            onBlur={() => {
              saveNote(dayjs(), "date")
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="Abholdatum"
            value={selectedCollectionDate}
            slotProps={{
              textField: {
                fullWidth: true,
                required: selectedNoteRequiresCollection,
              },
            }}
            onChange={(e) => {
              if (e) {
                setSelectedCollectionDate(e)
                saveNote(e, "date")
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TimePicker
            label="Abholzeit"
            value={selectedCollectionTime}
            slotProps={{ textField: { fullWidth: true } }}
            onChange={(e) => {
              if (e) {
                if (!e.isValid()) {
                  return
                }
                setSelectedCollectionTime(e || null)
                saveNote(e, "time")
              }
            }}
            minTime={dayjs().set("hour", 5)}
            maxTime={dayjs().set("hour", 18)}
            timeSteps={{ minutes: 60 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "70vh", width: "100%" }}>
            <DataGridPro
              getRowId={(row) => row.supplierName + "_" + row.articleId}
              apiRef={apiRef}
              rows={roundData.filter(
                (roundArticle) =>
                  roundArticle.supplierName === selectedSupplier?.name
              )}
              rowHeight={60}
              checkboxSelection
              columns={colDefs}
              disableRowSelectionOnClick
              localeText={dataGridDEde}
              loading={isLoading}
              onRowSelectionModelChange={handleSelectionModelChange}
              rowCount={roundOrderCount}
              processRowUpdate={(updatedRow, originalRow) => {
                if (
                  updatedRow.quantityTotal >=
                    originalRow.quantityForCustomers &&
                  updatedRow.quantityTotal !== originalRow.quantityTotal
                ) {
                  handleRoundQuantityItemEdit(
                    parseInt(updatedRow.articleId),
                    updatedRow.quantityTotal
                  )
                  return updatedRow
                } else if (updatedRow.description !== originalRow.description) {
                  handleRoundDescriptionItemEdit(
                    parseInt(updatedRow.articleId),
                    updatedRow.description
                  )
                  return updatedRow
                }
                return originalRow
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <RoundOrderAddItem
        open={addItemIsOpen}
        onClose={() => setAddItemIsOpen(false)}
        supplierName={selectedSupplier ? selectedSupplier.name : ""}
        supplierId={selectedSupplier ? selectedSupplier.id : -1}
        onSave={handleRoundItemAdd}
      />
      <RoundOrderSplitItemDialog
        open={splitItemIsOpen}
        onClose={() => setSplitItemIsOpen(false)}
        selection={selection}
        supplierName={selectedSupplier ? selectedSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        onSplit={handleRoundItemsSplit}
        isLoading={isLoading}
      />
      <RoundOrderMoveItemDialog
        open={moveItemIsOpen}
        onClose={() => setMoveItemIsOpen(false)}
        selection={selection}
        supplierName={selectedSupplier ? selectedSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        supplierItemCrossRefs={supplierItemCrossRefs}
        onMove={handleMoveItems}
        isLoading={isLoading}
      />
      <RoundOrderDeleteItemDialog
        open={deleteItemIsOpen}
        onClose={() => setDeleteItemIsOpen(false)}
        selection={selection}
        supplierName={selectedSupplier ? selectedSupplier.name : ""}
        supplierOrderInfo={supplierOrderInfo}
        onDelete={handleDeleteItems}
        isLoading={isLoading}
      />
    </Container>
  )
}

export default RoundOrderPage
