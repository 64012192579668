import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import {
  RoundOrderArticle,
  SplitableStatus,
  SupplierOrderItem,
} from "generated/graphql"
import { fetchSplitableMergeable } from "queries/getSplitableMergeableStatuslist"
import { useEffect, useMemo, useState } from "react"

export interface RoundOrderSplitItemDialogProps {
  open: boolean
  onClose: () => void
  supplierName: string
  selection: RoundOrderArticle[]
  supplierOrderInfo: SupplierOrderItem[]
  onSplit: (
    splitItems: SupplierOrderItem[],
    selectedStatus: number,
    statusCodesToSumUp: number[]
  ) => void
  isLoading: boolean
}

export const RoundOrderSplitItemDialog = (
  props: RoundOrderSplitItemDialogProps
): JSX.Element => {
  const {
    open,
    onClose,
    selection,
    supplierOrderInfo,
    supplierName,
    onSplit,
    isLoading,
  } = props

  const [selectedSplitItems, setSelectedSplitItems] = useState<number[]>([])
  const [selectedStatus, setSelectedStatus] = useState<number>(0)
  const [statusCodes, setStatusCodes] = useState<SplitableStatus[]>([])
  const [allSelectedStatuses, setAllSelectedStatuses] = useState<any>([])
  const selectedValues = useMemo(
    () => allSelectedStatuses.filter((v: any) => v.isSelected),
    [allSelectedStatuses]
  )

  const cols: GridColDef<SupplierOrderItem>[] = [
    { field: "id", headerName: "Lieferant Bestellnr.", width: 150 },
    { field: "orderID", headerName: "Kunde Bestellnr.", width: 150 },
    { field: "ewID", headerName: "EwID", width: 100 },
    { field: "bdb", headerName: "BDB", width: 120 },
    {
      field: "botname",
      headerName: "Artikel",
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="body1" component="div">
              {params.row.botname}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="div">
              {params.row.sorte} - {params.row.size}
            </Typography>
          </Box>
        )
      },
      width: 300,
    },
    { field: "quantity", headerName: "Anzahl", width: 100 },
  ]

  const filterSelctedData = (
    data: SupplierOrderItem[]
  ): SupplierOrderItem[] => {
    const l: SupplierOrderItem[] = []
    for (let i = 0; i < selection.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (selection[i].articleId === data[j].bdb) {
          l.push(data[j])
        }
      }
    }
    return l
  }

  const handleSelectionModelChange = (
    selectionModel: GridRowSelectionModel
  ) => {
    // WE know that it is a number array since we defined it as such
    setSelectedSplitItems(selectionModel as number[])
  }

  const handleSplitItems = () => {
    const splitItems: SupplierOrderItem[] = []
    for (let i = 0; i < supplierOrderInfo.length; i++) {
      if (selectedSplitItems.includes(supplierOrderInfo[i].id)) {
        splitItems.push(supplierOrderInfo[i])
      }
    }
    onSplit(
      splitItems,
      selectedStatus,
      selectedValues.map((v: any) => v.status)
    )
    // Reset the selected items
    setSelectedSplitItems([])
    setSelectedStatus(0)
    setAllSelectedStatuses(
      statusCodes.map((v) => {
        return { ...v, isSelected: false }
      })
    )
  }

  useEffect(() => {
    fetchSplitableMergeable().then((response) => {
      if (response?.getSplitMergeStatuses) {
        setStatusCodes(response.getSplitMergeStatuses)
        setAllSelectedStatuses(
          response.getSplitMergeStatuses.map((v) => {
            return { ...v, isSelected: false }
          })
        )
      }
    })
  }, [setStatusCodes, setAllSelectedStatuses])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Artikel-Splitten von {supplierName}</DialogTitle>
      <DialogContent sx={{ height: "70vh", width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "60vh", width: "100%" }}>
              <DataGridPro
                rows={filterSelctedData(supplierOrderInfo)}
                columns={cols}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionModelChange}
                disableRowSelectionOnClick
                localeText={dataGridDEde}
                loading={isLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="nextStatusCode"
              getOptionLabel={(option) => option.status + " - " + option.remark}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option.status === value.status
              }
              value={
                statusCodes.find((x) => x.status === selectedStatus) ?? null
              }
              onChange={(_, newValue) => {
                if (newValue) setSelectedStatus(newValue.status)
              }}
              options={statusCodes.filter((v) => v.splitable)}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              id="multiple-status"
              getOptionLabel={(option) => option.status + " - " + option.remark}
              fullWidth
              isOptionEqualToValue={(option, value) =>
                option.status === value.status
              }
              multiple
              value={selectedValues}
              onChange={(_, newValue) => {
                setAllSelectedStatuses(
                  statusCodes.map((v) => {
                    return {
                      ...v,
                      isSelected: newValue.some((x) => x.status === v.status),
                    }
                  })
                )
              }}
              options={statusCodes.filter((v) => v.mergeable)}
              renderInput={(params) => (
                <TextField {...params} label="Weitere Status Codes beachten" />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelectedSplitItems([])
            onClose()
          }}
          fullWidth
          variant="contained"
          color="secondary"
        >
          Abbrechen
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          fullWidth
          onClick={handleSplitItems}
          disabled={selectedSplitItems.length === 0 || selectedStatus === 0}
          color="primary"
        >
          Splitten
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
