import {
  CategoryFilterInput,
  CategoriesQuery,
  CategoriesQueryVariables,
} from "../generated/graphql"
import { gql } from "graphql-tag"
import { fetchGQL } from "app/api"

const CATEGORIES = gql`
  query categories($in: CategoryFilterInput!) {
    categories(filter: $in) {
      id
      title
    }
  }
`

export const getCategories = async (
  input: CategoryFilterInput
): Promise<CategoriesQuery | undefined> => {
  const variables: CategoriesQueryVariables = {
    in: input,
  }
  const data = await fetchGQL<CategoriesQuery, CategoriesQueryVariables>(
    CATEGORIES,
    variables
  )
  return data
}
