import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material"
import { OrderStatus } from "generated/graphql"
import { fetchSplitableMergeable } from "queries/getSplitableMergeableStatuslist"
import { orderStatus } from "queries/orderStatus"
import { useEffect, useState } from "react"

type OrderStatusAutocompleteProps = {
  status: Number
  handleOrderStatusChange: (newStatus: number) => void
  isSplitDialog?: boolean
}

export const OrderStatusAutocomplete = (
  props: OrderStatusAutocompleteProps
) => {
  const { status, handleOrderStatusChange, isSplitDialog } = props
  const [stati, setStati] = useState<OrderStatus[]>()
  const [value, setValue] = useState<OrderStatus>()
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    if (isSplitDialog) {
      fetchSplitableMergeable().then((data) => {
        if (data?.getSplitMergeStatuses) {
          const statis = data.getSplitMergeStatuses
            .filter((v) => v.splitable)
            .map((v) => {
              return {
                status: v.status,
                etiStop: v.etiStop,
                verteilenStop: v.verteilenStop,
                packStop: v.packStop,
                remark: v.remark,
              }
            })
          setStati(statis)
          const orderStatus = statis.find((item) => item.status === status)
          setValue(orderStatus)
        }
      })
    } else {
      orderStatus().then((data) => {
        if (data?.orderStatus && data) {
          const orderStati: OrderStatus[] = data.orderStatus
          let orderStatus = orderStati.find((item) => item.status === status)
          if (orderStatus === undefined) {
            // edgecase status is not in the backend list yet!
            const item = {
              status: Number(status),
              etiStop: false,
              verteilenStop: false,
              packStop: false,
              remark: "Status nicht in der Statuslist Tabelle (Datenbank)",
            }
            orderStatus = item
            orderStati.push(item)
          }
          setStati(orderStati)
          setValue(orderStatus)
        }
      })
    }
  }, [status, isSplitDialog])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const newValue = stati?.find(
        (item) => item.status === parseInt(inputValue)
      )
      if (newValue) {
        setValue(newValue)
        handleOrderStatusChange(newValue.status)
      }
    }
  }

  return (
    <>
      {stati && (
        <>
          <Autocomplete
            fullWidth
            value={value}
            inputValue={inputValue}
            options={stati}
            getOptionLabel={(option) =>
              option.status.toString() + ": " + option.remark
            }
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue)
            }}
            onChange={(_, newValue) => {
              if (newValue) {
                setValue(newValue)
                handleOrderStatusChange(newValue.status)
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.status}: {option.remark}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: handleKeyDown,
                }}
              />
            )}
          />
          {value && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: 1,
                    gap: 0.5,
                  }}
                >
                  <Chip
                    label={"Etikettieren"}
                    color={value.etiStop ? "error" : "primary"}
                    icon={value.etiStop ? <CloseIcon /> : <DoneIcon />}
                  />

                  <Chip
                    label={"Verteilen"}
                    color={value.verteilenStop ? "error" : "primary"}
                    icon={value.verteilenStop ? <CloseIcon /> : <DoneIcon />}
                  />

                  <Chip
                    label={"Packen"}
                    color={value.packStop ? "error" : "primary"}
                    icon={value.packStop ? <CloseIcon /> : <DoneIcon />}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
