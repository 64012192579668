import { fetchGQL } from "app/api"
import { OrderByStatusAndBdbQuery, OrderByStatusAndBdbQueryVariables } from "generated/graphql"
import { gql } from "graphql-tag"

const ORDERS_BY_STATUS_AND_BDB = gql`
query orderByStatusAndBDB($status: String!, $bdb: String!) {
  ordersByStatusAndBDB(status: $status, bdb: $bdb) {
    orderID
    ewID
    custId
    quantity
    isSingle
    sh
    notiz
    country
    zip
    zielDat
  }
}

`

export const ordersByStatusAndBdb = async (
    status: string,
    bdb: string
): Promise<OrderByStatusAndBdbQuery | undefined> => {
  const variables: OrderByStatusAndBdbQueryVariables= {status,bdb}
  const data = await fetchGQL<OrderByStatusAndBdbQuery, OrderByStatusAndBdbQueryVariables>(
    ORDERS_BY_STATUS_AND_BDB,
    variables
  )
  return data
}
