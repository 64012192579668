import { fetchGQL } from "app/api"
import {
    DeleteFilterTemplateMutation,
    DeleteFilterTemplateMutationVariables,
    FilterTemplatesQuery,
    SaveFilterTemplateMutation,
    SaveFilterTemplateMutationVariables
} from "generated/graphql"
import { gql } from "graphql-tag"

const GET_FILTER_TEMPLATES = gql`
query filterTemplates {
  filterTemplates {
    name
    template
  }
}
`

const SAVE_FILTER_TEMPLATE = gql`
mutation saveFilterTemplate($name: String!, $template: String!) {
  saveFilterTemplate(name: $name, template: $template)
}`

const DELETE_FILTER_TEMPLATE = gql`
mutation DeleteFilterTemplate($name: String!) {
  deleteFilterTemplate(name: $name)
}
`

export const getFilterTemplates = async (): Promise<FilterTemplatesQuery| undefined> => {
  const data = await fetchGQL<
    FilterTemplatesQuery,
    null
  >(GET_FILTER_TEMPLATES, null)
  return data
}

export const saveFilterTemplate = async (name: string, template: string): Promise<SaveFilterTemplateMutation| undefined> => {
    const vars: SaveFilterTemplateMutationVariables = {
        name,
        template
    }
  const data = await fetchGQL<
    SaveFilterTemplateMutation,
    SaveFilterTemplateMutationVariables
    
  >(SAVE_FILTER_TEMPLATE, vars)
  return data
}

export const deleteFilterTemplate = async (name: string): Promise<DeleteFilterTemplateMutation| undefined> => {
    const vars: DeleteFilterTemplateMutationVariables= {
        name,
    }
  const data = await fetchGQL<
    DeleteFilterTemplateMutation,
    DeleteFilterTemplateMutationVariables 
  >(DELETE_FILTER_TEMPLATE, vars)
  return data
}