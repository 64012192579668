import { Box, Button, Container } from "@mui/material"

import { Update } from "@mui/icons-material"
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { dataGridDEde } from "constants/dataGridLocale"
import { SupplierCollection } from "generated/graphql"
import { getSupplierCollections } from "queries/supplierCollections"
import { useEffect, useState } from "react"

export const DriverAppAdminPanelPage = (): JSX.Element => {
  const [rows, setRows] = useState<SupplierCollection[]>([])

  const getCollections = async () => {
    const collections = await getSupplierCollections({ activeOnly: true })
    if (collections) {
      setRows(collections.supplierCollections)
    }
  }

  useEffect(() => {
    getCollections()
  }, [])

  const cols: GridColDef[] = [
    { field: "supplierName", headerName: "Lieferant", width: 150 },
    { field: "round", headerName: "Runde", width: 100 },
    {
      field: "city",
      headerName: "Ort",
      width: 250,
      valueGetter: (params) => {
        const row = params.row
        return `${row.address.postCode || ""} ${row.address.city || ""}`
      },
    },
    { field: "count", headerName: "Anzahl", width: 100 },
    {
      field: "pickupDate",
      headerName: "Abholung",
      width: 120,
      valueGetter: (params) => {
        const newDate = new Date(params.row.pickupDate)
        return newDate
      },

      type: "date",
    },
    {
      field: "pickupTime",
      headerName: "Abholung ab",
      width: 120,
      valueGetter: (params) => {
        const row = params.row
        if (row.pickupTime === "") return ""
        return `${row.pickupTime.slice(0, -3) + " Uhr"}`
      },
    },
    { field: "driverName", headerName: "Fahrer", width: 150 },
    {
      field: "isCollected",
      headerName: "Abgeholt",
      width: 100,
      type: "boolean",
    },
    {
      field: "collectedAt",
      headerName: "Abgeholt am/um",
      width: 150,
      valueGetter: (params) => {
        if (!params.row.collectedAt) return ""
        const newDate = new Date(params.row.collectedAt)
        return newDate
      },
      type: "dateTime",
    },
    { field: "note", headerName: "Bemerkung", width: 300 },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <Button startIcon={<Update />} onClick={() => getCollections()}>
            Aktualisieren
          </Button>
          <GridToolbarFilterButton />
        </Box>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    )
  }

  return (
    <Container maxWidth={false}>
      <DataGridPro
        getRowId={(row) => `${row.supplierId}_${row.round}`}
        rowHeight={80}
        rows={rows}
        columns={cols}
        localeText={dataGridDEde}
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[{ value: rows.length, label: "alle" }, 25, 50, 100]}
      />
    </Container>
  )
}
