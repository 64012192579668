import { Alert, Button, Container, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro"
import { useAppDispatch } from "app/hooks"
import { qSnack } from "app/snackSlice"
import DialogPDF from "components/DialogPDF/DialogPDF"
import LinkRenderer from "components/LinkRenderer/LinkRenderer"
import { dataGridDEde } from "constants/dataGridLocale"
import dayjs, { Dayjs } from "dayjs"
import { PackageStat, Printer } from "generated/graphql"
import { fetchPDFBase64 } from "queries/downloadPDF"
import {
  fetchPackageStats,
  fetchPackageStatsByOrderID,
} from "queries/fetchPackageStats"
import { fetchPrinters } from "queries/fetchPrinters"
import { rabenLabelPrint } from "queries/fetchRaben"
import { rmlLabelPrint } from "queries/fetchRmlLabelPrint"
import { rsLabelPrint } from "queries/printRsLabel"
import { shipcloudLabelPrint } from "queries/shipcloudLabel"
import { useEffect, useState } from "react"
import { germanDateFormatter } from "utils/datefmt"
import { formatDateFromDate } from "utils/formatDate"
import { shortcutsItems } from "utils/shortcutItems"

const PackageStatsPage = () => {
  const [loading, setloading] = useState(false)
  const [orderID, setOrderID] = useState("")
  const dispatch = useAppDispatch()
  const [pdfDialogIsOpen, setPdfDialogIsOpen] = useState(false)
  const [pdfURL, setPdfURL] = useState("")
  const [kind, setKind] = useState("")
  const [pdfBlob, setPdfBlob] = useState<string>("")
  const [printerList, setPrinterList] = useState<Printer[]>([])

  const handleClosePdfDialog = () => {
    setPdfDialogIsOpen(false)
  }

  const handleOpenPdfDialog = async (url: string, carrier: string) => {
    setKind(carrier)
    setPdfURL(url)
    fetchPDFBase64(url, carrier).then((res) => {
      if (res?.downloadPdf) {
        setPdfBlob(res.downloadPdf)
        setPdfDialogIsOpen(true)
      } else {
        dispatch(
          qSnack({
            msg: "Fehler beim Laden des PDFs",
            severity: "error",
          })
        )
      }
    })
  }

  const handlePrint = (printerIpPort: string) => {
    if (kind === "RS Logistik") {
      rsLabelPrint(pdfURL, printerIpPort).then((data) => {
        if (data?.printRsLabel) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    } else if (kind === "Raben Group") {
      rabenLabelPrint(printerIpPort, pdfURL).then((data) => {
        if (data?.rabenLabelPrint) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    } else if (kind === "Robert Müller") {
      rmlLabelPrint(printerIpPort, pdfURL).then((data) => {
        if (data?.rmlLabelPrint) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    } else {
      shipcloudLabelPrint(printerIpPort, pdfURL).then((data) => {
        if (data?.printShipcloudLabel) {
          dispatch(
            qSnack({
              msg: "Label an den Drucker gesendet, wenn nichts passiert bitte Drucker überprüfen.",
              severity: "success",
            })
          )
        }
      })
    }
  }

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs(),
    dayjs(),
  ])
  const [packageStatsData, setPackageStatsData] = useState<PackageStat[]>([])

  const handleOrderID = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderID(event.target.value)
  }

  const handleOrderIDCommit = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (orderID === "") {
        dispatch(
          qSnack({ msg: "Bitte eine Bestellnummer eingeben", severity: "info" })
        )
      }
      if (orderID !== "") {
        const numRegex = /^[0-9]+$/
        // chekc if orderID is a number
        if (numRegex.test(orderID) === false) {
          dispatch(
            qSnack({ msg: "Bitte eine Zahl eingeben", severity: "info" })
          )
          return
        }
        fetchPackageStatsByOrderID(orderID).then((res) => {
          if (res?.packageStatsByOrderID) {
            dispatch(qSnack({ msg: "Daten geladen", severity: "success" }))
            setPackageStatsData(res.packageStatsByOrderID)
          }
        })
      }
    }
  }

  useEffect(() => {
    fetchPrinters().then((res) => {
      if (res?.printers) {
        setPrinterList(res.printers)
      }
    })
  }, [])

  useEffect(() => {
    setloading(true)
    const fromDateString = dateRange[0]
      ? formatDateFromDate(dateRange[0].toDate())
      : ""
    const toDateString = dateRange[1]
      ? formatDateFromDate(dateRange[1].toDate())
      : ""
    if (!fromDateString || !toDateString) return
    if (fromDateString === "" || toDateString === "") return
    fetchPackageStats(fromDateString, toDateString)
      .then((res) => {
        if (res?.packageStats) {
          setPackageStatsData(res.packageStats)
        }
      })
      .finally(() => setloading(false))
  }, [dateRange])

  const columns: GridColDef[] = [
    {
      field: "orderID",
      headerName: "Bestellnummer",
      width: 150,
      editable: false,
    },
    {
      field: "createdAt",
      headerName: "Datum",
      width: 200,
      editable: false,
      renderCell(params) {
        const date = new Date(params.value)
        return germanDateFormatter.format(date)
      },
    },
    {
      field: "ps",
      headerName: "PS",
      width: 250,
      editable: false,
    },
    {
      field: "carrier",
      headerName: "Carrier",
      width: 150,
      editable: false,
    },

    {
      field: "trackingURL",
      headerName: "Tracking Link",
      width: 150,
      editable: false,
      renderCell: (params) => <LinkRenderer {...params} />,
    },
    {
      field: "labelURL",
      headerName: "PDF",
      width: 150,
      editable: false,
      renderCell(params) {
        const { row } = params
        let url = row.labelURL
        if (row.carrier === "Raben Group") {
          //package id in case of raben group
          url = row.ps
        }
        return (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={url === ""}
              onClick={() => {
                handleOpenPdfDialog(url as string, row.carrier)
              }}
            >
              PDF
            </Button>
          </>
        )
      },
    },
  ]

  return (
    <Container maxWidth="xl">
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: 500 }}>
              <DateRangePicker
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                localeText={{ start: "Von", end: "Bis" }}
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                disableFuture={true}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <TextField
            label="Bestellnummer"
            variant="outlined"
            value={orderID}
            onChange={handleOrderID}
            onKeyUp={handleOrderIDCommit}
            sx={{ marginRight: 2 }}
          />
          <Alert severity="info">
            Drücke Enter um nach der Bestell ID zu suchen. (hierbei wird das
            Datum ignoriert)
          </Alert>
        </Box>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Box
          sx={{
            height: "calc(100vh - 30vh)",
            width: "100%",
          }}
        >
          <DataGridPro
            showColumnVerticalBorder
            rows={packageStatsData}
            columns={columns}
            localeText={dataGridDEde}
            getRowId={(item) => item.id}
            loading={loading}
            sortingOrder={["desc", "asc"]}
          />
        </Box>
      </Grid>
      <DialogPDF
        isOpen={pdfDialogIsOpen}
        onClose={handleClosePdfDialog}
        pdfB64={pdfBlob}
        pl={printerList}
        onPrint={handlePrint}
      />
    </Container>
  )
}

export default PackageStatsPage
