import { fetchGQL } from "app/api"
import { Dayjs } from "dayjs"
import {
  CustomerRefundCreateMutation,
  CustomerRefundCreateMutationVariables,
  CustomerRefundSettleMutation,
  CustomerRefundSettleMutationVariables,
  CustomerRefundsQuery,
  CustomerRefundsQueryVariables,
} from "generated/graphql"
import { gql } from "graphql-tag"

// FETCH REFUND
const CUSTOMER_REFUND = gql`
query customerRefunds($in: CustomerRefundInput!) {
  customerRefunds(in: $in) {
    totalRows
    rows {
      id
      orderID
      articleID
      customerID
      amount
      createdAt
      createdBy
      settledAt
      settledBy
      note
    }
  }
}

`

export const getCustomerRefund = async (
  limit: number,
  offset: number,
  fromDate: Dayjs,
  toDate: Dayjs,
  onlySettled: boolean
): Promise<
  CustomerRefundsQuery | undefined 
> => {
  const from = fromDate.format("YYYY-MM-DD")
  const to = toDate.format("YYYY-MM-DD")
  const vars: CustomerRefundsQueryVariables = {
    in: {
    limit,
    offset,
    from,
    to,
    onlySettled
    }
  }
  const data = await fetchGQL<CustomerRefundsQuery, CustomerRefundsQueryVariables>(CUSTOMER_REFUND, vars)
  return data
}

//CREATE REFUND
const CUSTOMER_REFUND_CREATE = gql`
  mutation customerRefundCreate($in: CustomerRefundCreateInput!) {
    customerRefundCreate(in: $in) {
      id
      orderID
      articleID
      customerID
      amount
      createdAt
      createdBy
      settledAt
      settledBy
      note
    }
  }
`

export const customerOrderCreate = async (
  vars: CustomerRefundCreateMutationVariables
): Promise<CustomerRefundCreateMutation | undefined> => {
  const data = await fetchGQL<
    CustomerRefundCreateMutation,
    CustomerRefundCreateMutationVariables
  >(CUSTOMER_REFUND_CREATE, vars)
  return data
}

//SETTLE REFUND
const CUSTOMER_REFUND_SETTLE = gql`
  mutation customerRefundSettle($in: CustomerRefundSettleInput!) {
    customerRefundSettle(in: $in) {
      id
      orderID
      articleID
      customerID
      amount
      createdAt
      createdBy
      settledAt
      settledBy
      note
    }
  }
`

export const customerOrderSettle = async (
  id: number
): Promise<CustomerRefundSettleMutation | undefined> => {
  const vars = { in: { id } }
  const data = await fetchGQL<
    CustomerRefundSettleMutation,
    CustomerRefundSettleMutationVariables
  >(CUSTOMER_REFUND_SETTLE, vars)
  return data
}
