import { fetchGQL } from "app/api"
import { gql } from "graphql-tag"
import {
  ArticleSearchFromOrderQuery,
  ArticleSearchFromOrderQueryVariables,
  ArticleSearchQuery,
  ArticleSearchQueryVariables,
  ArticleVariantsQuery,
  ArticleVariantsQueryVariables,
} from "../generated/graphql"

const ARTICLE_SEARCH_FROM_ORDER_ID = gql`
  query articleSearchFromOrder($oid: Int!) {
    articleSearchFromOrder(oid: $oid) {
      bdb
      pflNR
      botname
      gername
      price
      reducedPrice
      sizeFrom
      sizeTo
      quali
      sorte
      lform
    }
  }
`

const ARTICLE_VARIANTS = gql`
  query articleVariants($pflnr: String!, $getInactive: Boolean!) {
    articleVariants(pflnr: $pflnr, getInactive: $getInactive) {
      bdb
      pflNR
      botname
      gername
      price
      reducedPrice
      sizeFrom
      sizeTo
      quali
      sorte
      lform
    }
  }
`

const ARTICLE_SEARCH = gql`
  query articleSearch($t: String!, $getInactive: Boolean!) {
    articleSearch(t: $t, getInactive: $getInactive) {
      bdb
      pflNR
      botname
      gername
      price
      reducedPrice
      sizeFrom
      sizeTo
      quali
      sorte
      lform
    }
  }
`

export const articleSearch = async (
  t: string,
  getInactive: boolean
): Promise<ArticleSearchQuery | undefined> => {
  const variables: ArticleSearchQueryVariables = {
    t,
    getInactive,
  }
  const data = await fetchGQL<ArticleSearchQuery, ArticleSearchQueryVariables>(
    ARTICLE_SEARCH,
    variables
  )
  return data
}

export const fetchArticleVariants = async (
  pflnr: string,
  getInactive: boolean
): Promise<ArticleVariantsQuery | undefined> => {
  const variables: ArticleVariantsQueryVariables = {
    pflnr,
    getInactive,
  }
  const data = await fetchGQL<
    ArticleVariantsQuery,
    ArticleVariantsQueryVariables
  >(ARTICLE_VARIANTS, variables)
  return data
}

export const fetchArticleSearchFromOrder = async (
  orderId: number
): Promise<ArticleSearchFromOrderQuery | undefined> => {
  const variables: ArticleSearchFromOrderQueryVariables = {
    oid: orderId,
  }
  const data = await fetchGQL<
    ArticleSearchFromOrderQuery,
    ArticleSearchFromOrderQueryVariables
  >(ARTICLE_SEARCH_FROM_ORDER_ID, variables)
  return data
}
